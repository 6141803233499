import { createContext } from "react"

import { AgentMachineSnapshot, AgentStateMachineRef } from "./machine-logic"

type AgentStateContextType = {
  agentRef: React.MutableRefObject<connect.Agent | null>
  send: AgentStateMachineRef["send"]
  state: AgentMachineSnapshot
}

export const AgentStateContext = createContext<AgentStateContextType | null>(
  null,
)
