import { Slider } from "@/components/ui/slider"

interface ProgressBarProps {
  currentTime: number
  duration: number
  onSeek: (time: number) => void
}

const formatTime = (seconds: number) => {
  const mins = Math.floor(seconds / 60)
  const secs = Math.floor(seconds % 60)

  return `${mins}:${secs.toString().padStart(2, "0")}`
}

const ProgressBar = ({ currentTime, duration, onSeek }: ProgressBarProps) => {
  return (
    <div className="space-y-1">
      <div className="flex justify-between text-xs text-muted-foreground">
        <span>{formatTime(currentTime)}</span>
        <span>{formatTime(duration)}</span>
      </div>
      <Slider
        value={[currentTime]}
        max={duration}
        step={1}
        onValueChange={([value]) => onSeek(value)}
      />
    </div>
  )
}

export { ProgressBar }
