import {
  statusMap,
  StatusUsageContext,
  statusUsageMap,
} from "@repo/core/models"
import { useMutation } from "@tanstack/react-query"

import { useAgentState } from "@/agent-state"
import { persistenceService } from "@/agent-state/persistence"
import { useGetProcessingTime } from "@/components/CallView/track-processing-time"
import * as callViewValue from "@/components/CallView/value"
import { useAxiosClientContext } from "@/components/core/AxiosInstanceProvider/useAxiosClient"
import { createCallReportError } from "@/helpers/error"
import { isNull } from "@/helpers/typeguards"
import { useReactQueryOptions } from "@/hooks/reactQueryOptions"
import * as connectPanelValue from "@/pages/CustomConnectPanel/value"

import { useCallDurationData } from "./callDuration"
import { Timer } from "./callDurationTimer"
import { useContactDataHook } from "./contactData"
import { Logger, useLogger } from "./useLogger"

export type CallReportType =
  | "attempted-call"
  | "automatic-going-offline"
  | "automatic-pre-call"
  | "automatic-reset-view"
  | "disabled"
  | "manual-leaving-view"
  | "student-details-append-status"

export const useSendCallReport = ({
  currentCase,
  isInboundCall,
  onSuccess,
  timer,
}: {
  currentCase?: connectPanelValue.Decoder.CaseAndActivityFeeds | null
  isInboundCall?: boolean
  onSuccess?: () => void
  timer?: Timer | null
}) => {
  const logger = useLogger()
  const { send, state } = useAgentState()
  const isPseudoACW = state.matches({ busy: { after_call: "pseudo_acw" } })
  const inManualCall = state.context.displayStatus === "offline"
  const caseData = currentCase ?? state.context.taskData?.caseData
  const campaignId = caseData?.case.campaign_id
  const studentId = caseData?.case.student_id

  const { callType, currentTaskId, voiceContactId } = useContactDataHook()
  const getProcessingTime = useGetProcessingTime()
  const axiosClient = useAxiosClientContext()
  const { retry } = useReactQueryOptions()
  const { callDurationData } = useCallDurationData()

  const sendCallReportMutation = useMutation({
    mutationFn: async ({
      callReportType,
      formData,
    }: {
      callReportType: CallReportType
      formData?: callViewValue.Decoder.DecoderType
    }) => {
      if (isNull(studentId)) {
        throw new Error(`[${callReportType}] studentId is not found`)
      }

      if (isNull(campaignId)) {
        throw new Error(`[${callReportType}] campaignId is not found`)
      }
      const createdAt = new Date()
      const voiceCreatedAt = callDurationData?.call_start_timestamp
        ? new Date(callDurationData.call_start_timestamp)
        : new Date()

      const processingTime = getProcessingTime()
      const callReportDataParams = getCallReportDataByType({
        callReportType,
        inManualCall,
        formData,
        isInboundCall: isInboundCall ?? false,
        isPseudoACW,
        logger,
      })
      const callContact = voiceContactId
        ? {
            call_duration_seconds: timer
              ? timer.seconds + timer.minutes * 60 + timer.hours * 3600
              : null,
            contact_id: voiceContactId,
            created_at: voiceCreatedAt,
            type: callType,
          }
        : undefined
      const comment = formData?.memo
        ? { comment: formData.memo, created_at: createdAt }
        : undefined

      if (!campaignId || !studentId) {
        throw new Error(
          `campaignId and studentId should be available when sending the report`,
        )
      }

      const callReportData = {
        campaign_id: campaignId,
        student_id: studentId,
        status_event: {
          context: callReportDataParams.context,
          event_name: callReportDataParams.event_name,
          created_at: createdAt,
          processing_duration_seconds: processingTime,
          call_attempted: callReportDataParams.call_attempted,
        },
        comment: comment,
        scheduled_call_datetime: formData?.scheduledCallDate,
        call_contact: callContact,
        stop_most_recent_task: callReportDataParams.stop_most_recent_task,
        open_task_contact_id:
          state.context.taskMeta?.origin === "v2"
            ? state.context.taskMeta?.taskId
            : currentTaskId,
      }

      try {
        const response = await axiosClient.post("/call-reports", callReportData)
        const validResponse =
          await callViewValue.Decoder.saveReportSchema.parseAsync(response.data)

        if (!validResponse.success.length) {
          throw new Error(
            `[${callReportType}] Empty response from call report API`,
          )
        }

        send({ type: "SEND_CALL_REPORT", callReportType })

        return validResponse
      } catch (err) {
        throw createCallReportError(err, callReportData)
      }
    },
    onSuccess: () => {
      if (onSuccess) {
        onSuccess()
      }
    },
    retry,
  })

  return sendCallReportMutation
}

/**
 * Determines call report data based on the call report type and other parameters.
 *
 * @param callReportType - The type of call report
 * @param inManualCall - Whether the call is a manual call
 * @param formData - Optional form data from the call view
 * @param isInboundCall - Whether the call is inbound
 *
 * @returns An object with the following properties:
 * - event_name: The status or reason for the call event
 * - call_attempted: Whether a call was attempted
 * - stop_most_recent_task: Whether to stop the most recent task
 */
const getCallReportDataByType = ({
  callReportType,
  formData,
  inManualCall,
  isInboundCall,
  isPseudoACW,
  logger,
}: {
  callReportType: CallReportType
  formData: callViewValue.Decoder.DecoderType | undefined
  inManualCall: boolean
  isInboundCall: boolean
  isPseudoACW: boolean
  logger: Logger
}): {
  call_attempted: boolean
  context: StatusUsageContext | null
  event_name: string
  stop_most_recent_task: boolean
} => {
  const currContext = getUseCaseContext({
    callReportType,
    isPseudoACW,
    isInboundCall,
    inManualCall,
  })
  switch (callReportType) {
    case "manual-leaving-view":
      return {
        event_name: statusMap.LeaveAsIs,
        context: currContext,
        call_attempted: true,
        stop_most_recent_task: false,
      }
    case "student-details-append-status":
      return {
        event_name: formData?.reason || formData?.status || "",
        context: currContext,
        call_attempted: false,
        stop_most_recent_task: true,
      }
    case "automatic-going-offline":
      return {
        event_name: statusMap.Skip,
        context: currContext,
        call_attempted: false,
        stop_most_recent_task: false,
      }
    case "attempted-call":
      return {
        event_name: formData?.reason || formData?.status || "",
        call_attempted: true,
        context: currContext,
        stop_most_recent_task:
          formData?.status === statusMap.LeaveAsIs
            ? false
            : isInboundCall || inManualCall,
      }
    case "automatic-reset-view":
      return {
        event_name: statusMap.Skip,
        context: currContext,
        stop_most_recent_task: false,
        call_attempted: persistenceService.read().hasAttemptedCall, // to handle the case an error happens after a reload in after call work
      }
    case "automatic-pre-call":
      return {
        event_name: formData?.reason || formData?.status || statusMap.Skip,
        context: currContext,
        call_attempted: false,
        stop_most_recent_task: false,
      }
    default:
      logger.warn(
        `[${callReportType}] context not found:
         formData: ${JSON.stringify(formData)}
         inManualCall: ${inManualCall}
         isPseudoACW: ${isPseudoACW}
         isInboundCall: ${isInboundCall}`,
      )

      return {
        event_name: "",
        context: null,
        call_attempted: false,
        stop_most_recent_task: false,
      }
  }
}

export const getUseCaseContext = ({
  callReportType,
  inManualCall,
  isInboundCall,
  isPseudoACW,
}: {
  callReportType: CallReportType
  inManualCall: boolean
  isInboundCall: boolean
  isPseudoACW: boolean
}) => {
  switch (callReportType) {
    case "manual-leaving-view":
      return statusUsageMap.manualLeavingView
    case "student-details-append-status":
      return statusUsageMap.studentDetails
    case "automatic-going-offline":
      return statusUsageMap.automaticGoingOffline
    case "attempted-call":
      return getAttemptedCallContext(inManualCall, isPseudoACW, isInboundCall)
    case "automatic-reset-view":
      return statusUsageMap.automaticResetView
    case "automatic-pre-call":
      return statusUsageMap.automaticPreCall
    default:
      return null
  }
}

const getAttemptedCallContext = (
  inManualCall: boolean,
  isPseudoACW: boolean,
  isInboundCall: boolean,
) => {
  if (isInboundCall) {
    return statusUsageMap.automaticInbound
  }
  const callType = inManualCall ? "manual" : "automatic"
  const callStage = isPseudoACW ? "PreConnectedCall" : "Outbound"

  return statusUsageMap[`${callType}${callStage}`]
}
