import * as React from "react"

import Icon from "@/components/Icon"
import { cn } from "@/helpers/classNames"

const Card = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      "rounded-lg border bg-card text-card-foreground shadow-sm",
      className,
    )}
    {...props}
  />
))
Card.displayName = "Card"

const CardHeader = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("flex flex-col space-y-1.5 p-6", className)}
    {...props}
  />
))
CardHeader.displayName = "CardHeader"

const CardTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  <h3
    ref={ref}
    className={cn(
      "text-2xl font-semibold leading-none tracking-tight",
      className,
    )}
    {...props}
  />
))
CardTitle.displayName = "CardTitle"

const CardDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <p
    ref={ref}
    className={cn("text-sm text-muted-foreground", className)}
    {...props}
  />
))
CardDescription.displayName = "CardDescription"

const CardContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn("p-6 pt-0", className)} {...props} />
))
CardContent.displayName = "CardContent"

const CardFooter = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("flex items-center p-6 pt-0", className)}
    {...props}
  />
))
CardFooter.displayName = "CardFooter"

/** Used to display error messages at the top of the Call Panel view, not part of shadcn/ui */
function ErrorCard({
  children,
  className,
  heading,
  onClose,
}: React.PropsWithChildren<{
  className?: string
  heading: React.ReactNode
  onClose?: () => void
}>) {
  return (
    <div
      className={cn(
        "font-body-small-regular flex flex-col gap-y-2 rounded-sm border border-danger-200 bg-danger-100 px-4 py-3 text-neutral-600",
        className,
      )}
    >
      <div className="flex flex-row flex-wrap items-center gap-x-2.5">
        <Icon name="alert" className="text-danger-300" />
        <div className="font-body-small-bold grow" data-unmask>
          {heading}
        </div>
        {onClose && ( // TODO it should be a button, not a div, for accessibility!
          <div className="cursor-pointer" onClick={onClose}>
            <Icon name="close" className="text-danger-200" />
          </div>
        )}
      </div>
      <>{children}</>
    </div>
  )
}

export {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
  ErrorCard,
}
