import { z } from "zod"

import { useAgentState } from "@/agent-state"
import { datetimeSchema } from "@/helpers/zodSchemas"
import { useAgentProfile } from "@/hooks/agent-profile"
import { useQueryAPI } from "@/hooks/query-api"

export function useAgentData() {
  const { agentRef } = useAgentState()
  const { agentProfile } = useAgentProfile()
  const agentId = agentProfile?.id
  const agent = agentRef.current

  const campaignIds =
    agent
      ?.getRoutingProfile()
      .queues.map((queue) => queue.name)
      .filter(Boolean) || [] // remove `null` values

  return { campaignIds, agentId }
}

export function useAgentMetricsToday({
  agentId,
  campaignIds,
}: {
  agentId?: string
  campaignIds: string[]
}) {
  const startTime = new Date()
  startTime.setHours(0, 0, 0, 1)
  const endTime = new Date()
  endTime.setHours(23, 59, 59, 999)
  const dateRange = { from: startTime, to: endTime }

  return useQueryAPI({
    apiEndPoint: "/metrics/agents/campaign-breakdown",
    method: "POST",
    params: {
      connect_user_id: agentId,
      date_from: dateRange.from,
      date_to: dateRange.to,
    },
    data: {
      campaign_ids: campaignIds,
    },
    schema: agentPerformanceResponseSchema,
    options: {
      refetchInterval: 5 * 60 * 1000, // Refetch data every 5 minutes...
    },
  })
}

const agentPerformanceSchema = z.object({
  // we need only the 7 following metrics for agent performance in the call panel
  CallsPerHour: z.number().nullish(),
  TotalConnectedCallsPerHour: z.number().nullish(),
  ConnectedCallRate: z.number().nullish(),
  TotalCreditForEntryConfirmed: z.number().nullish(),
  CreditForEntryConfirmedCVR: z.number().nullish(),
  TotalCreditForTentativeEntry: z.number().nullish(),
  CreditForTentativeEntryCVR: z.number().nullish(),
})

const metaSchema = z.object({
  additionalInfo: z.object({ en: z.string(), ja: z.string() }),
})

export const agentMetricsMetadataSchema = z.object({
  CallsPerHour: metaSchema,
  TotalConnectedCallsPerHour: metaSchema,
  ConnectedCallRate: metaSchema,
  TotalCreditForEntryConfirmed: metaSchema,
  CreditForEntryConfirmedCVR: metaSchema,
  TotalCreditForTentativeEntry: metaSchema,
  CreditForTentativeEntryCVR: metaSchema,
})

export type AgentPerformanceMetrics = z.infer<typeof agentPerformanceSchema>

export type AgentPerformanceMetadata = z.infer<
  typeof agentMetricsMetadataSchema
>

const agentPerformanceResponseSchema = z.object({
  data: z.object({
    metrics: z.array(
      z.object({
        campaignId: z.string(),
        metricData: agentPerformanceSchema,
      }),
    ),
    metadata: agentMetricsMetadataSchema,
    cacheDetails: z.object({
      cacheSetTimestamp: datetimeSchema,
    }),
  }),
})
