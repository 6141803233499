import { FunctionComponent } from "react"
import { UseFormReturn } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { useAgentState } from "@/agent-state"
import { isInManualCall } from "@/agent-state/state-helpers"
import * as callViewValue from "@/components/CallView/value"
import { isNull } from "@/helpers/typeguards"
import * as connectPanelValue from "@/pages/CustomConnectPanel/value"

import { useCallReportOptions } from "./call-report-options"
import { CallReportStatelessForm } from "./call-report-stateless-form"
import { ACWFormStatus, useACWFormHook } from "./hook"

interface Props {
  className?: string
  currentCase: connectPanelValue.Decoder.CaseAndActivityFeeds | null
  form: UseFormReturn<callViewValue.Decoder.DecoderType>
  historyItem: connectPanelValue.ActivityFeedEntry.ActivityFeedEntryType | null
}

const CallReportForm: FunctionComponent<Props> = ({
  className,
  currentCase,
  form,
  historyItem,
}) => {
  const { isLoadingAgent, onSave, status } = useACWFormHook({ currentCase })
  const { t } = useTranslation()
  const { state } = useAgentState()

  const callReportType = useGetAutomaticCallReportType(status)

  const title = t(
    callReportType === "automatic-pre-call"
      ? "callResult.pcrTitle"
      : "callResult.title",
  )

  const options = useCallReportOptions({
    callReportType,
    latestRepeaterStatus: currentCase?.case.latest_repeater_status ?? null,
    isInboundCall: state.context.taskMeta?.callDirection === "inbound",
    isPseudoACW: state.matches({ busy: { after_call: "pseudo_acw" } }),
    inManualCall: isInManualCall(state),
  })

  // Disable the ACW form for the states that should never be encountered
  // in normal operation (e.g. missed task). See `closeTaskError` fn
  // where we don't send a call report but only close the contact.
  const isFormDisabled =
    state.matches({ busy: { error: "missed_task" } }) ||
    state.matches({ busy: { error: "rejected_task" } }) ||
    state.matches({ busy: { error: "missed_inbound_call" } })

  return (
    <CallReportStatelessForm
      className={className}
      form={form}
      isNewItem={isNull(historyItem)}
      isLoadingAgent={isLoadingAgent}
      onSave={onSave}
      isDisabled={isFormDisabled}
      title={title}
      availableCallReportOptions={options}
    />
  )
}

const useGetAutomaticCallReportType = (status: ACWFormStatus) => {
  const { state } = useAgentState()

  const isWontCall = state.matches({ busy: "no_call" })

  if (isWontCall) return "automatic-pre-call"
  if (status === "disabled") return "disabled"

  return "attempted-call"
}

export default CallReportForm
