import { useSearchParams } from "react-router"

import { DateRangeValue } from "@/components/date-range-nav"
import { updateSearchParams } from "@/helpers/url"
import { useQueryAPI } from "@/hooks/query-api"

import {
  agentListSchema,
  CallReportSearchOptions,
  searchOptionsSchema,
} from "./call-history-schemas"

export function useCallHistorySearch() {
  const [params, setParams] = useSearchParams()

  const searchOptions = searchOptionsSchema.parse({
    date: {
      range: params.get("range") || undefined,
      to: params.get("to") || undefined,
    },
    statuses: params.getAll("statuses") || [],
    agentIds: params.getAll("agentIds") || [],
    campaignIds: params.getAll("campaignIds") || [],
    search_query: params.get("search_query") || "",
    all_agents: params.get("all_agents") === "true",
  })

  const onChange =
    <K extends keyof CallReportSearchOptions>(key: K) =>
    (value?: CallReportSearchOptions[K]) => {
      setParams((current) => {
        // Handle boolean values as strings since updateSearchParams doesn't accept boolean
        if (key === "all_agents") {
          if (value === true) {
            current.set(key.toString(), "true")
          } else {
            current.delete(key.toString())
          }
        } else {
          // Use type assertion to handle the valid cases for updateSearchParams
          updateSearchParams(
            current,
            key.toString(),
            value as Date | DateRangeValue | string[] | string | undefined,
          )
        }

        return current
      })
    }

  return { searchOptions, onChange }
}

export function useAgentList() {
  const { data, isLoading } = useQueryAPI({
    apiEndPoint: "/agents/list",
    method: "GET",
    schema: agentListSchema,
  })

  return { agentList: data?.data?.agents, isLoading }
}
