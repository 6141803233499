import { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"

import { useAgentState } from "@/agent-state"
import { ShowCallReportErrorToast } from "@/components/ACWForm/report-error-card"
import {
  CallReportSubmitError,
  ShowErrorToast,
} from "@/components/CallPanel/call-error-card"
import * as connectPanelValue from "@/pages/CustomConnectPanel/value"

import { useACWButtonsHook } from "./ACWButtons/hook"
import { useCallPanelActions } from "./callpanel-actions"
import { CallPanelButtons } from "./callpanel-buttons"
import { CallPanelCard } from "./callpanel-card"
import { CallPanelStatusIcon } from "./callpanel-status-icon"
import { useCallPanelHook } from "./hook"
import { IncomingCallNotificationCard } from "./incoming-call-card"

interface Props {
  acwButtonState: ReturnType<typeof useACWButtonsHook>
  callPanelState: ReturnType<typeof useCallPanelHook>
  currentCase: connectPanelValue.Decoder.CaseAndActivityFeeds | null
}

const CallPanel: FunctionComponent<Props> = ({
  acwButtonState,
  callPanelState,
}) => {
  const { campaignName, phoneNumber, shouldDisplayCounter, timer } =
    callPanelState
  const { t } = useTranslation()
  const { state } = useAgentState()

  const isConnectingInboundCall = state.matches({ busy: "inbound_ringing" })

  const actions = useCallPanelActions({ phoneNumber })

  return (
    <div
      data-testid="call-panel"
      className="col-span-full row-start-2 flex flex-col lg:col-start-2"
    >
      {acwButtonState.isError && <CallReportSubmitError className="mb-2" />}
      <ShowErrorToast className="mb-2" />
      <ShowCallReportErrorToast className="mb-2" />
      {/* Show a notification card on incoming Inbound calls */}
      <IncomingCallNotificationCard isConnecting={isConnectingInboundCall} />
      <CallPanelCard
        campaignName={campaignName}
        phoneNumber={phoneNumber}
        timer={shouldDisplayCounter ? timer : null}
        status={<CallPanelStatusIcon state={state} />}
        buttons={
          <CallPanelButtons
            campaignName={campaignName}
            caseData={state.context.taskData?.caseData || null}
            actions={actions}
            acwButtonState={{
              ...acwButtonState,
              isDisabled: !state.context.historyItem,
            }}
          />
        }
        error={
          state.matches({ busy: { error: "missed_task" } })
            ? t("callPanel.status.missedTask")
            : null
        }
      />
    </div>
  )
}

export default CallPanel
