import { z } from "zod"

import { getAgentId } from "@/helpers/agent"
import { idSchema } from "@/helpers/zodSchemas"
import { useQueryAPI } from "@/hooks/query-api"

import { useAgentState } from "."

export function useFetchAgentProfile() {
  const { agentRef } = useAgentState()

  const agentARN = agentRef.current?.getConfiguration().agentARN
  const connectUserId = getAgentId(agentARN)

  return useQueryAPI({
    apiEndPoint: "/agents",
    params: {
      connect_user_id: connectUserId,
    },
    schema: z.object({
      data: userInformationSchema,
    }),
    options: {
      enabled: !!connectUserId,
    },
  })
}

export const userInformationSchema = z.object({
  arn: z.string(),
  email: z.string().email(),
  id: idSchema,
  identity_info: z.object({
    first_name: z.string(),
    last_name: z.string(),
  }),
  phone_config: z.object({
    after_contact_work_time_limit: z.number(),
    auto_accept: z.boolean(),
    desk_phone_number: z.string(),
    phone_type: z.enum(["SOFT_PHONE", "DESK_PHONE"]),
  }),
  security_profile_names: z.string().array().nonempty(),
  // tags: z.object({})
})

export type AgentProfile = z.infer<typeof userInformationSchema>
