import { useAgentState } from "@/agent-state"
import { AgentProfile } from "@/agent-state/use-fetch-agent-profile"
import { isNull } from "@/helpers/typeguards"

export function useAgentProfile() {
  const {
    state: {
      context: { agentProfile },
    },
  } = useAgentState()

  const isManager = Boolean(
    agentProfile &&
      hasRole(agentProfile, ["Admin", "CallCenterManagerAndAgent"]),
  )

  return { agentProfile, isManager, isLoading: isNull(agentProfile) }
}

export function usePermissions() {
  const { isManager } = useAgentProfile()

  return {
    canEditCampaigns: isManager,
  }
}

function hasRole(agentProfile: AgentProfile, roles: string[]) {
  return agentProfile.security_profile_names.some((profileName) =>
    roles.includes(profileName),
  )
}
