/* eslint-disable no-console */
import { z } from "zod"

import {
  isInboundCall,
  isTask,
} from "@/components/core/ConnectCCP/contact_helpers"
import { UndocumentedAgentStateType } from "@/helpers/atoms"
import { getContactPhoneNumber } from "@/helpers/phoneNumber"

import { useAgentState } from "."
import { AgentState } from "./state-types"
import { extractTaskProps } from "./task-data"

export const pseudoACWStatus = [
  "FailedConnectAgent",
  "FailedConnectCustomer",
  "MissedCallCustomer",
  "BadAddressAgent",
  "BadAddressCustomer",
  "LineEngagedAgent",
  "LineEngagedCustomer",
]

// All possible agent states triggered by agent.onStateChange()
export const connectAgentStatusSchema = z.enum([
  "AfterCallWork",
  "Available",
  "Busy", // in a call
  "CallingCustomer", // making an outbound call
  "Init",
  "MissedCallAgent", // agent did not pick up the phone
  "Offline",
  "PendingBusy", // a student is calling
  "PseudoACW", // custom state we created from AWS Connect console
  ...pseudoACWStatus,
])

export type ConnectAgentStatus = z.infer<typeof connectAgentStatusSchema>

/** Main hook to link the AWS Connect Agent to the state machine  */
export function useSetupConnectListeners() {
  const { agentRef, send } = useAgentState()

  const onAuthenticated = () => {
    connect.agent((agent) => {
      agentRef.current = agent

      send({
        type: "AGENT_LOADED",
        agentData: { queueNames: getQueueNames(agent) },
      })

      agent.onStateChange(({ newState, oldState }) => {
        console.log(">> agent.onStateChange", oldState, "=>", newState)

        const result = connectAgentStatusSchema.safeParse(newState)
        if (!result.success) {
          console.error(">> Invalid agent status", newState)

          return
        }

        send({ type: "CHANGE_CONNECT_AGENT_STATUS", status: result.data })
      })

      connect.contact((contact) => {
        console.log(">> Contact", contact.getType(), contact.getStatus().type)

        sendConnectContactEvent(contact)

        contact.onError((contact) => {
          console.error(">> Contact Error", contact.getStatus().type)
          send({
            type: "CONTACT_ERROR",
            status: contact.getStatus().type,
            isInboundCall: isInboundCall(contact),
            isTask: isTask(contact),
          })
        })
      })

      function sendConnectContactEvent(contact: connect.Contact) {
        const data = getEventData(contact)

        send({ type: "NEW_TASK", task: data })
      }

      function getEventData(contact: connect.Contact) {
        const contactId = contact.getContactId()
        const isContactTask = isTask(contact)
        const data = extractTaskProps(agent)

        const campaignId = data?.campaignId || getCampaignId(agent)
        const caseId = data?.caseId || undefined
        const phoneNumber = data?.phoneNumber || getContactPhoneNumber(agent)
        const studentId = data?.studentId || undefined

        const taskMeta: AgentState["taskMeta"] = {
          callDirection: isInboundCall(contact) ? "inbound" : "outbound",
          caseId,
          origin: "connect",
          phoneNumber: phoneNumber || undefined,
          campaignId: campaignId || undefined,
          studentId,
          contactId: contactId,
          taskId: isContactTask ? contactId : null,
          voiceId: isContactTask ? null : contactId,
          contactStatus: contact.getStatus().type,
        }

        return taskMeta
      }
    })
  }

  return { onAuthenticated }
}

function getCampaignId(agent: connect.Agent) {
  return agent?.getContacts()[0]?.getQueue().name
}

function getQueueNames(agent: connect.Agent) {
  return (
    agent
      ?.getRoutingProfile()
      .queues.map((queue) => queue.name)
      .filter(Boolean) || [] // remove `null` values
  )
}

export function isContactOnHold(agent: connect.Agent) {
  const contacts = agent.getContacts(connect.ContactType.VOICE)

  if (!contacts.length) {
    return false
  }

  const activeConnection = contacts[0]?.getInitialConnection()

  return activeConnection?.isOnHold()
}

export function useGetConnectAgentStatus():
  | connect.AgentStateType
  | UndocumentedAgentStateType {
  const { agentRef } = useAgentState()

  return agentRef.current?.getStatus().type || connect.AgentStateType.INIT
}
