import { maxBy } from "es-toolkit"

import { AgentProfile } from "@/agent-state/use-fetch-agent-profile"
import { useFormValidation } from "@/hooks/formValidation"
import { agentProfilesMapping } from "@/models/agent-profile"

interface HeaderHookInputType {
  agentProfile: AgentProfile | null
}

interface HeaderHookType {
  agentKind?: string
  agentName: string
}

const useHeaderHook = ({
  agentProfile,
}: HeaderHookInputType): HeaderHookType => {
  const { isKanji } = useFormValidation()

  const firstName = agentProfile?.identity_info.first_name ?? ""
  const lastName = agentProfile?.identity_info.last_name ?? ""

  const agentName = isKanji(lastName || firstName)
    ? `${lastName}${firstName}`
    : `${firstName} ${lastName}`

  const securityProfiles =
    agentProfile?.security_profile_names.map(toCamelCase) ?? []

  const agentKind = getTopProfile()

  function toCamelCase(value: string) {
    return value.charAt(0).toLowerCase() + value.slice(1)
  }

  function getTopProfile() {
    const topProfile = maxBy(securityProfiles, (profile) => {
      const profileValue = agentProfilesMapping[profile]

      return profileValue
    })

    return topProfile
  }

  return { agentName, agentKind }
}

export { useHeaderHook }
