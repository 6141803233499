import { useState } from "react"
import { useTranslation } from "react-i18next"

import { Button } from "@/components/ui/button"
import {
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"

export function DialogGoOfflineWithContact({
  onClose,
  onSkip,
}: {
  onClose: (value: boolean) => void
  onSkip: () => Promise<void>
}) {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)

  const handleGoOffline = async () => {
    try {
      setIsLoading(true)
      await onSkip()
      onClose(true)
    } catch (error) {
      setIsLoading(false)
    }
  }

  return (
    <DialogContent isPending={isLoading}>
      <DialogHeader>
        <DialogTitle>{t("global.modals.goOffline.title")}</DialogTitle>
      </DialogHeader>
      <DialogBody>{t("global.modals.goOffline.description")}</DialogBody>
      <DialogFooter>
        <Button variant="outline" onClick={() => onClose(false)}>
          {t("global.button.cancel")}
        </Button>
        <Button onClick={() => handleGoOffline()} isLoading={isLoading}>
          {t("global.button.skip")}
        </Button>
      </DialogFooter>
    </DialogContent>
  )
}
