import { AuthError, ResetPasswordOutput, SignInOutput } from "@aws-amplify/auth"
import axios from "axios"
import { useSetAtom } from "jotai"
import { RESET } from "jotai/utils"
import { z } from "zod"

import { persistenceService } from "@/agent-state/persistence"
import { clearConnectTimestamp } from "@/components/core/ConnectCCP/connect_session"
import { callDurationDataAtom, cognitoAuthStatusAtom } from "@/helpers/atoms"
import { hasValue } from "@/helpers/typeguards"
import { idSchema } from "@/helpers/zodSchemas"
import { useLogger } from "@/hooks/useLogger"
import { useManualCallAtom } from "@/hooks/useManualCallAtom"
import { contactStorageService } from "@/services/localStorageService"

import { config } from "../config"
import { useAuthWithAmplify } from "./authWithAmplify"

export type ValidInputCredentials = {
  password: string
  username: string
}

type UseAuthHookType = {
  changePassword: ({
    email,
    newPassword,
    verificationCode,
  }: {
    email: string
    newPassword: string
    verificationCode: string
  }) => Promise<void>
  checkAuth: () => Promise<void>
  checkIsValidAuthSession: () => Promise<boolean>
  getConnectCredentials: () => Promise<ConnectCredentials>
  login: (credentials: ValidInputCredentials) => Promise<SignInOutput>
  logout: (shouldClearAllState?: boolean) => Promise<void>
  refreshTokenAfterFailure: () => Promise<string | undefined>
  requestPasswordReset: (email: string) => Promise<ResetPasswordOutput | string>
}

const credentialsSchema = z.object({
  AccessToken: z.string(),
  AccessTokenExpiration: z.number(),
  RefreshToken: z.string(),
  RefreshTokenExpiration: z.number(),
})

export type ConnectCredentials = z.infer<typeof credentialsSchema>

// Connect Credentials response includes the agent's user ID. However, will not store in state
// because it can be fetched from the agent's ARN
const connectCredentialsResponseSchema = z.object({
  success: z.string(),
  data: z.object({
    connect_user_id: idSchema,
    login_credentials: credentialsSchema,
  }),
  reqId: idSchema,
})

const authUrl = `https://${config.apiEndpoint}/ccp-login`
export const connectLoginUrl = `https://${config.connectInstanceAlias}.my.connect.aws/auth/sign-in`
export const connectLogoutUrl = `https://${config.connectInstanceAlias}.my.connect.aws/connect/logout`
// const connectRefreshUrl = `https://${config.connectInstanceAlias}.my.connect.aws/auth/refresh`

const useAuthHook = (): UseAuthHookType => {
  const setCognitoAuthStatus = useSetAtom(cognitoAuthStatusAtom)
  const log = useLogger()
  const setCallDurationData = useSetAtom(callDurationDataAtom)
  const { resetInManualCall } = useManualCallAtom()

  const currentAuth = useAuthWithAmplify()

  const login = async ({
    password,
    username,
  }: ValidInputCredentials): Promise<SignInOutput> => {
    return currentAuth.signIn({ password, username })
  }

  const userSignOut = async (): Promise<void> => {
    await currentAuth.signOut()

    setCognitoAuthStatus({ current: "NOT_AUTHENTICATED" })
  }

  const fallbackLogout = (): void => {
    try {
      userSignOut()
      clearConnectTimestamp()
      contactStorageService.clear()
      resetInManualCall()
      persistenceService.reset()
      setCallDurationData(RESET)
    } catch (err) {
      setCognitoAuthStatus({ current: "NOT_AUTHENTICATED" })
      clearConnectTimestamp()
      contactStorageService.clear()
      resetInManualCall()
      persistenceService.reset()
      setCallDurationData(RESET)
    }
  }

  const logout = async (shouldClearAllState = false) => {
    try {
      // First sign out of Amazon Connect
      await fetch(connectLogoutUrl, {
        credentials: "include",
        mode: "no-cors",
      })

      // https://github.com/amazon-connect/amazon-connect-streams/issues/365
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const eventBus = connect?.core?.getEventBus()
      if (hasValue(eventBus)) {
        eventBus.trigger(connect.EventType.TERMINATE)
      }

      // We do not clear the cognito timer (see timerIdRef) here because it is done in the Protected route
      // It calls a cleanup hook when the component is unmounted
      // We also navigate to the login page in the Protected route
      await userSignOut()
      clearConnectTimestamp()

      // clear call related data from local storage on logout
      contactStorageService.clear()
      if (shouldClearAllState) {
        setCallDurationData(RESET)
        persistenceService.reset()
      }

      resetInManualCall()
    } catch (err) {
      log.error(err)
      // clears the full state in local storage on logout
      fallbackLogout()
    } finally {
      // Trigger a full reload of the page after logout to avoid state cleanup and help with memory issues
      window.location.href = "/login"
    }
  }

  const getConnectCredentials = async (): Promise<ConnectCredentials> => {
    const accessToken = await currentAuth.getAccessToken()

    const credentialsResponse = await axios.post(
      authUrl,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    )

    const validResponse = await connectCredentialsResponseSchema.parseAsync(
      credentialsResponse.data,
    )

    const {
      data: { login_credentials: loginCredentials },
    } = validResponse

    return loginCredentials
  }

  const resetAuthentication = async (err: unknown) => {
    const isAgentLoggedOut =
      (err instanceof Error && err.message === "No cognito user found") ||
      (err instanceof AuthError &&
        (err.name === "NotAuthorizedException" ||
          err.name === "UserNotFoundException"))

    // Required in order to redirect to /login page
    setCognitoAuthStatus({ current: "NOT_AUTHENTICATED" })

    if (!isAgentLoggedOut) {
      log.error(err)
      // Logout the agent from both cognito and connect whenever we fail to validate a session
      // or to refresh it
      await logout()
    }
  }

  const checkAuth = async () => {
    try {
      await currentAuth.checkAuth()
    } catch (err) {
      await resetAuthentication(err)
    }
  }

  /**
   * Fetch a new token after a 401 error, when the token has expired (see Axios interceptor)
   * The session does not have to be validated in this scenario
   * */
  const refreshTokenAfterFailure = async () => {
    try {
      const accessToken = await currentAuth.refreshTokenAfterFailure()

      return accessToken
    } catch (err) {
      await resetAuthentication(err)
    }
  }

  const checkIsValidAuthSession = async () => {
    const isAuthSessionValid = await currentAuth.checkIsValidAuthSession()

    return isAuthSessionValid
  }

  // Send a password reset message (email only for now)
  const requestPasswordReset = (
    email: string,
  ): Promise<ResetPasswordOutput | string> => {
    return currentAuth.requestPasswordReset(email)
  }

  const changePassword = ({
    email,
    newPassword,
    verificationCode,
  }: {
    email: string
    newPassword: string
    verificationCode: string
  }): Promise<void> => {
    return currentAuth.changePassword({
      email,
      newPassword,
      verificationCode,
    })
  }

  return {
    login,
    logout,
    getConnectCredentials,
    checkAuth,
    refreshTokenAfterFailure,
    checkIsValidAuthSession,
    requestPasswordReset,
    changePassword,
  }
}

export { useAuthHook }
