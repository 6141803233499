import { useEffect } from "react"

import { useAgentState } from "@/agent-state"
import { useErrorToast } from "@/components/CallPanel/call-error-card"
import { getContactPhoneNumber } from "@/helpers/phoneNumber"
import { Timer, useCallDuration } from "@/hooks/callDuration"
import { useConnectActionsHook } from "@/hooks/connectActions"
import { useCallPanelLogger } from "@/hooks/useLogger"

interface CallPanelHookType {
  campaignName: string
  phoneNumber: string | null
  shouldDisplayCounter: boolean
  timer: Timer | null
}

const useCallPanelHook = ({
  campaignName,
  phoneNumber,
}: {
  campaignName: string
  isManualCall?: boolean
  phoneNumber?: string
}): CallPanelHookType => {
  const { agentRef, state } = useAgentState()
  const agent = agentRef.current

  const { pushCallError } = useErrorToast()
  const { closeContact } = useConnectActionsHook()
  const { endTimer, timer } = useCallDuration()
  const log = useCallPanelLogger()

  const isNetworkConnectionError = state.matches({
    busy: { error: "missed_call_agent_state" },
  })

  const shouldDisplayCounter = !(
    state.matches({ busy: "no_call" }) || state.matches({ busy: "error" })
  )

  const isAfterCallWorkState = state.matches({ busy: "after_call" })

  // Stop timer when the student ends the call
  useEffect(() => {
    if (isAfterCallWorkState) {
      endTimer()
    }
  }, [isAfterCallWorkState]) // eslint-disable-line react-hooks/exhaustive-deps

  // When the outbound voice call connection is lost for 30 seconds and the call is put into missed state
  // we close the contact and show an error card.
  useEffect(() => {
    if (isNetworkConnectionError) {
      try {
        pushCallError("missed_call_agent_state")
        closeContact()
        endTimer()
      } catch (error) {
        log.error(error)
      }
    }
  }, [isNetworkConnectionError]) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    phoneNumber: phoneNumber || getContactPhoneNumber(agent),
    shouldDisplayCounter,
    campaignName,
    timer,
  }
}

export { type Timer, useCallPanelHook }
