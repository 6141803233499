import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
import { LoaderCircle } from "lucide-react"
import invariant from "tiny-invariant"

import { useAgentState } from "@/agent-state"
import { useCallPanelHook } from "@/components/CallPanel/hook"
import { AlertLoadingError } from "@/components/ui/alert"
import { PageBreadcrumb } from "@/components/ui/breadcrumb"
import { CallDurationProvider } from "@/hooks/callDuration"
import * as customConnectPanelValues from "@/pages/CustomConnectPanel/value"

import { StudentCallView } from "./call-view/call-view"
import { StudentDefaultView } from "./default-view/default-view"
import { ManualCallView } from "./shared/manual-call-view"
import {
  CaseWithActivityFeed,
  useFetchStudentCase,
  useFetchStudentProfile,
} from "./student-details-api"

export function StudentDetailsPage() {
  const { campaign_id, student_id } = useParams()
  invariant(campaign_id, "campaign_id is required")
  invariant(student_id, "student_id is required")

  const caseQuery = useFetchStudentCase(campaign_id, student_id)
  const studentProfileQuery = useFetchStudentProfile(student_id)

  const caseData = caseQuery.data?.data
  const studentProfile = studentProfileQuery.data?.data

  const isLoading = caseQuery.isLoading || studentProfileQuery.isLoading

  return (
    <main className="pt-10">
      <div className="container flex flex-col gap-4">
        {isLoading && (
          <div className="justify-center">
            <LoaderCircle className="animate-spin" />
          </div>
        )}
        {caseQuery.error && (
          <AlertLoadingError
            title="Unable to load the case"
            error={caseQuery.error}
          />
        )}
        {studentProfileQuery.error && (
          <AlertLoadingError
            title="Unable to load the student profile"
            error={studentProfileQuery.error}
          />
        )}
        {caseData && studentProfile && (
          <>
            <BreadcrumbArea campaign_id={campaign_id} student_id={student_id} />
            <CallDurationProvider>
              <StudentView
                caseData={caseData}
                studentProfile={studentProfile}
              />
            </CallDurationProvider>
          </>
        )}
      </div>
    </main>
  )
}

function StudentView({
  caseData,
  studentProfile,
}: {
  caseData: CaseWithActivityFeed
  studentProfile: customConnectPanelValues.Decoder.StudentProfile
}) {
  const campaignName = caseData.case.campaign_id
  const phoneNumber = studentProfile.phone_number

  const { state } = useAgentState()
  const { timer } = useCallPanelHook({
    campaignName,
    phoneNumber,
    isManualCall: true,
  })

  const { historyItem } = state.context

  // Switch between two different layouts, depending on the display mode
  if (!state.matches("busy")) {
    return (
      <StudentDefaultView
        caseData={caseData}
        studentProfile={studentProfile}
        callPanel={
          <ManualCallView
            caseData={caseData}
            historyItem={historyItem}
            campaignName={campaignName}
            phoneNumber={phoneNumber}
            timer={null}
          />
        }
      />
    )
  } else {
    return (
      <>
        <StudentCallView
          caseData={caseData}
          studentProfile={studentProfile}
          historyItem={historyItem}
          callPanel={
            <ManualCallView
              caseData={caseData}
              campaignName={campaignName}
              historyItem={historyItem}
              phoneNumber={phoneNumber}
              timer={timer}
            />
          }
        />
      </>
    )
  }
}

function BreadcrumbArea({
  campaign_id,
}: {
  campaign_id: string
  student_id: string
}) {
  const { t } = useTranslation()
  const items = [
    { label: t("campaigns.details.viewList"), path: "/campaigns" },
    { label: campaign_id, path: `/campaigns/${campaign_id}` },
  ]

  return <PageBreadcrumb items={items} />
}
