import { DateRangeNav } from "@/components/date-range-nav"
import { SearchBox } from "@/components/search/search-box"

import { StatusPicker } from "../Campaigns/view/status-picker"
import { CallReportSearchOptions } from "./call-history-schemas"
import { useCallHistorySearch } from "./call-history-search"
import { AgentsPicker } from "./pickers/agents-picker"
import { CampaignPicker } from "./pickers/campaign-picker"

type Props = {
  onChange: ReturnType<typeof useCallHistorySearch>["onChange"]
  searchOptions: CallReportSearchOptions
  showAgentsPicker?: boolean
}

export function CallHistorySearchBar({
  onChange,
  searchOptions,
  showAgentsPicker,
}: Props) {
  return (
    <div className="my-4 grid gap-4 sm:grid-cols-2 lg:flex lg:flex-row lg:flex-wrap">
      <CampaignPicker
        allowReset
        values={searchOptions.campaignIds}
        onChange={onChange("campaignIds")}
      />
      <StatusPicker
        allowReset
        values={searchOptions.statuses}
        onChange={onChange("statuses")}
      />
      {showAgentsPicker && (
        <AgentsPicker
          allowReset
          values={searchOptions.agentIds}
          onChange={onChange("agentIds")}
        />
      )}
      <DateRangeNav value={searchOptions.date} onChange={onChange("date")} />
      <SearchBox
        defaultValue={searchOptions.search_query || ""}
        onChange={onChange("search_query")}
        placeholder="callHistory.searchPlaceholder"
      />
    </div>
  )
}
