import { noop } from "es-toolkit"
import {
  type ActorRefFrom,
  assertEvent,
  assign,
  setup,
  type SnapshotFrom,
} from "xstate"

import { pseudoACWStatus } from "./connect-listeners"
import { persistenceService } from "./persistence"
import type { AgentDisplayStatus, AgentEvent, AgentState } from "./state-types"

export const rootMachine = setup({
  types: {
    context: {} as AgentState,
    events: {} as AgentEvent,
  },
  actions: {
    syncConnectStatus: assign(({ event }) => {
      assertEvent(event, "CHANGE_CONNECT_AGENT_STATUS")

      return {
        connectAgentStatus: event.status,
      }
    }),
    setTaskMeta: assign(({ event }, params?: { wasInterrupted?: boolean }) => {
      assertEvent(event, ["NEW_TASK"])

      return {
        taskMeta: { ...event.task, wasInterrupted: params?.wasInterrupted },
      }
    }),
    setTaskData: assign(({ event }) => {
      assertEvent(event, ["TASK_DATA_LOADED"])

      return {
        taskData: event.task,
      }
    }),
    // When leaving the busy state, reset everything
    resetTask: assign({
      taskData: undefined,
      taskMeta: undefined,
    }),
    saveCallReport: assign(({ event }) => {
      assertEvent(event, "SAVE_CALL_REPORT")

      return { historyItem: event.callReport }
    }),
    resetCallReport: assign(() => ({ historyItem: null })),
    skipContactAndDisplayOffline: assign(({ event }) => {
      assertEvent(event, "TOGGLE_DISPLAY_STATUS")
      const newDisplayStatus: AgentDisplayStatus = "offline"

      return { displayStatus: newDisplayStatus }
    }),
    // Side effects to persist the agent state when reloading the page
    setManualCall: () => {
      persistenceService.makeCall(true)
    },
    makeCallAttempt: () => {
      persistenceService.makeCall(false)
    },
    resetPersistedState: () => {
      persistenceService.reset()
    },
    // Side effects to manipulate the agent status
    // implemented using machine.provide(implementations)
    enterPseudoACW: noop,
    exitPseudoACW: noop,
  },
  // Guards are used as the conditions to trigger state transitions, based on the current Connect agent status
  guards: {
    isConnectOnline: ({ context, event }) => {
      assertEvent(event, "CHANGE_CONNECT_AGENT_STATUS")

      const inManualCall = context.persistedContext.isManualCall

      return !inManualCall && event.status !== "Offline"
    },
    isConnectOffline: ({ context, event }) => {
      assertEvent(event, "CHANGE_CONNECT_AGENT_STATUS")

      const inManualCall = context.persistedContext.isManualCall

      return !inManualCall && event.status === "Offline"
    },
    isOffline: ({ context }) => context.displayStatus === "offline",
    isAgentCalling: ({ context: { connectAgentStatus } }) =>
      connectAgentStatus === "CallingCustomer",
    isStudentCalling: ({ context, event }) => {
      return (
        context.connectAgentStatus === "PendingBusy" ||
        (event.type === "CHANGE_CONNECT_AGENT_STATUS" &&
          event.status === "PendingBusy")
      )
    },
    isInCall: ({ event }) => {
      assertEvent(event, "CHANGE_CONNECT_AGENT_STATUS")

      return event.status === "Busy"
    },
    isFailedCallEvent: ({ event }) => {
      assertEvent(event, "CHANGE_CONNECT_AGENT_STATUS")
      const { status } = event

      return (
        pseudoACWStatus.includes(status) ||
        // take into accounts some direct transitions from `CallingCustomer` to `Offline` or `Available` when the student does not pick the phone
        status === "Offline" ||
        status === "Available"
      )
    },
    isMissedInboundCallEvent: ({ event }) => {
      assertEvent(event, "CHANGE_CONNECT_AGENT_STATUS")

      return event.status === "MissedCallAgent"
    },
    isMissedCall: ({ context: { connectAgentStatus } }) =>
      connectAgentStatus === "MissedCallAgent",
    isACW: ({ context: { connectAgentStatus } }) =>
      connectAgentStatus === "AfterCallWork",
    isLoadedInPseudoACW: () => persistenceService.read().isPseudoACW,
    isLoadedManualCall: () => persistenceService.read().isManualCall,
    isMissedTask: ({ context: { taskMeta } }) =>
      taskMeta?.contactStatus === "missed",
    // Contact error events transitions
    isRejectedTask: ({ event }) => {
      assertEvent(event, "CONTACT_ERROR")

      return event.status === "rejected" && event.isTask
    },
    isRejectedCall: ({ event }) => {
      assertEvent(event, "CONTACT_ERROR")

      return event.status === "rejected" && !event.isTask
    },
    agentIsSwitchingOffline: ({ event }) => {
      assertEvent(event, "TOGGLE_DISPLAY_STATUS")

      return event.status === "offline"
    },
  },
}).createMachine({
  id: "root",
  context: {
    agentProfile: null,
    connectAgentStatus: "Init",
    displayStatus: "loading",
    persistedContext: persistenceService.read(),
    historyItem: null,
  },
  initial: "loading",
  on: {
    // Keep track of any change of the agent status in Connect
    CHANGE_CONNECT_AGENT_STATUS: {
      actions: "syncConnectStatus",
    },
    AGENT_LOADED: {
      actions: assign({
        queueNames: ({ event }) => event.agentData.queueNames,
      }),
    },
    AGENT_PROFILE_LOADED: {
      actions: assign({
        agentProfile: ({ event }) => event.agentProfile,
      }),
    },
  },
  states: {
    loading: {
      on: {
        CHANGE_CONNECT_AGENT_STATUS: [
          {
            guard: "isLoadedManualCall",
            target: "manualCallRestore",
            actions: [
              "syncConnectStatus",
              assign(() => ({ displayStatus: "offline" })),
            ],
          },
          {
            guard: "isConnectOffline",
            target: "offline",
            actions: [
              assign(() => ({ displayStatus: "offline" })),
              "syncConnectStatus",
            ],
          },
          {
            guard: "isConnectOnline",
            target: "available",
            actions: [
              assign(() => ({ displayStatus: "online" })),
              "syncConnectStatus",
            ],
          },
        ],
        AGENT_LOADED: {
          actions: assign({
            queueNames: ({ event }) => event.agentData.queueNames,
          }),
        },
      },
    },
    offline: {
      always: [
        {
          guard: "isAgentCalling",
          target: "busy.outbound_ringing",
          actions: "setManualCall",
        },
        { guard: "isLoadedInPseudoACW", target: "busy.after_call.pseudo_acw" }, // reloading the page after a failed manual call
      ],
      on: {
        TOGGLE_DISPLAY_STATUS: {
          target: "available",
          actions: assign(() => ({ displayStatus: "online" })),
        },
      },
    },
    available: {
      entry: assign(() => ({ task: undefined, taskData: undefined })),
      on: {
        NEW_TASK: {
          target: "busy.loading_task",
          actions: [{ type: "setTaskMeta" }],
        },
        TOGGLE_DISPLAY_STATUS: {
          target: "offline",
          actions: [
            assign(({ event }) => {
              return { displayStatus: event.status }
            }),
          ],
        },
      },
    },
    busy: {
      initial: "loading_task",
      exit: ["resetTask", "resetPersistedState", "resetCallReport"],
      on: {
        SAVE_CALL_REPORT: { actions: "saveCallReport" },
        // Take into account various errors from Contact
        CONTACT_ERROR: [
          { target: "busy.error.rejected_task", guard: "isRejectedTask" },
          { target: "busy.error.rejected_call", guard: "isRejectedCall" },
        ],
      },
      states: {
        loading_task: {
          on: {
            TASK_DATA_LOADED: [
              {
                guard: "isLoadedInPseudoACW",
                target: "after_call.pseudo_acw",
                actions: ["setTaskData"],
              },
              {
                guard: "isACW",
                target: "after_call.normal_acw",
                actions: ["setTaskData"],
              },
              {
                guard: "isStudentCalling",
                target: "inbound_ringing",
                actions: ["setTaskData"],
              },
              {
                guard: "isMissedCall",
                target: "error.missed_inbound_call",
                actions: ["setTaskData"],
              },
              // specific case: a task is loaded with the status `missed`
              {
                guard: "isMissedTask",
                target: "error.missed_task",
                actions: ["setTaskData"],
              },
              // default case, pre_call state with CALL / WON'T CALL
              {
                target: "pre_call",
                actions: ["setTaskData"],
              },
            ],
            ENTER_RESET_MODE: { target: "reset" },
            NEW_TASK: {
              target: "loading_task",
              actions: [
                { type: "setTaskMeta", params: { wasInterrupted: true } },
              ],
            },
          },
        },
        reset: {
          on: {
            LEAVE_RESET_MODE: { target: "#root.available" },
          },
        },
        pre_call: {
          always: [
            {
              guard: "isAgentCalling",
              target: "outbound_ringing",
              actions: "makeCallAttempt",
            },
          ],
          on: {
            WONT_CALL: { target: "no_call" },
            // After "Skip and Go offline" modal
            SEND_CALL_REPORT: {
              target: "#root.offline",
              actions: assign(() => ({ displayStatus: "offline" })),
            },
            // An agent can be interrupted by an inbound call, while in the pre_call state
            NEW_TASK: {
              target: "loading_task",
              actions: [
                { type: "setTaskMeta", params: { wasInterrupted: true } },
              ],
            },
          },
        },
        no_call: {
          on: {
            GO_BACK_TO_CALL_MODE: { target: "pre_call" },
            SEND_CALL_REPORT: { target: "#root.available" },
            // Agent clicks "Won't call" and then Selects "Offline"
            TOGGLE_DISPLAY_STATUS: {
              guard: "agentIsSwitchingOffline",
              target: "#root.offline",
              actions: "skipContactAndDisplayOffline",
            },
            NEW_TASK: {
              target: "loading_task",
              actions: [{ type: "setTaskMeta" }],
            },
          },
        },
        outbound_ringing: {
          on: {
            CHANGE_CONNECT_AGENT_STATUS: [
              { guard: "isInCall", target: "in_call" },
              {
                guard: "isFailedCallEvent",
                target: "after_call.pseudo_acw",
              },
            ],
          },
        },
        inbound_ringing: {
          on: {
            REJECT_INBOUND_CALL: { target: "#root.available" },
            CHANGE_CONNECT_AGENT_STATUS: [
              { guard: "isInCall", target: "in_call" },
              {
                guard: "isMissedInboundCallEvent",
                target: "error.missed_inbound_call",
              },
              {
                guard: "isFailedCallEvent",
                target: "#root.available", // go back to available state if the student ends the call before the agent picks up
              },
            ],
          },
        },
        in_call: {
          always: [{ guard: "isACW", target: "after_call.normal_acw" }],
        },
        after_call: {
          on: {
            SEND_CALL_REPORT: [
              { guard: "isOffline", target: "#root.offline" }, // after manual call, back to offline node state
              { target: "#root.available" },
            ],
          },
          initial: "normal_acw",
          states: {
            normal_acw: {},
            pseudo_acw: {
              entry: ["enterPseudoACW"],
              exit: ["exitPseudoACW"],
            },
          },
        },
        error: {
          on: {
            CLOSE_TASK_AFTER_ERROR: { target: "#root.available" },
            // Going offline while in an error state such as missed call or missed task
            TOGGLE_DISPLAY_STATUS: {
              guard: "agentIsSwitchingOffline",
              target: "#root.offline",
              actions: "skipContactAndDisplayOffline",
            },
          },
          initial: "internal_error",
          states: {
            missed_inbound_call: {},
            missed_call_agent_state: {}, // probably a network error, introduced here: https://github.com/scoville/cci-call-panel/pull/381
            missed_task: {},
            internal_error: {},
            rejected_task: {},
            rejected_call: {},
          },
        },
      },
    },
    manualCallRestore: {
      // this is a temporary state to restore the agent state when the page is refreshed while the agent is in a manual call
      always: [
        { guard: "isAgentCalling", target: "busy.outbound_ringing" },
        { guard: "isInCall", target: "busy.in_call" },
        { guard: "isACW", target: "busy.after_call.normal_acw" },
        { guard: "isLoadedInPseudoACW", target: "busy.after_call.pseudo_acw" },
      ],
    },
  },
})

export type AgentStateMachineRef = ActorRefFrom<typeof rootMachine>

export type AgentMachineSnapshot = SnapshotFrom<typeof rootMachine>

export type AgentStateMachineValue = AgentMachineSnapshot["value"]
