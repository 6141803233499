import { FunctionComponent } from "react"

import { useDisplayAgentStatus } from "@/agent-state/agent-status-hooks"

import { AgentStatusSwitcher } from "./agent-status-switcher"
import { useAgentStatusHook } from "./hook"

const AgentStatusSelect: FunctionComponent = () => {
  const { status } = useDisplayAgentStatus()
  const { isDisabled, onChange } = useAgentStatusHook()

  return (
    <AgentStatusSwitcher
      isDisabled={isDisabled}
      status={status}
      onChange={onChange}
      data-unmask
    />
  )
}

export default AgentStatusSelect
