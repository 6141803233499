import { z } from "zod"

import { useAgentState } from "@/agent-state"
import { useQueryAPI } from "@/hooks/query-api"

export type RefillStatus =
  | "error"
  | "no-work"
  | "rate-limit-error"
  | "scheduled-work"
  | "scheduling-work"

const refillResponseSchema = z.object({
  success: z.string(),
  data: z.object({
    posted_campaignIds: z.string().array(),
    campaign_ids_to_refill: z.string().array(),
  }),
})

export function useFetchRequestWork({ queues }: { queues: string[] }) {
  return useQueryAPI({
    apiEndPoint: "/campaign-filters/request-queue-refill",
    data: { campaign_ids: queues },
    method: "post",
    schema: refillResponseSchema,
    options: {
      enabled: queues.length > 0,
    },
  })
}

export function useRequestWork() {
  const { state } = useAgentState()
  const queues = state.context.queueNames || []
  const { data, ...rest } = useFetchRequestWork({ queues })

  const successMessage = data?.success
  const isNoWork =
    successMessage === "No queues to refill for the provided campaign ids"
  const isAlreadyScheduled =
    successMessage ===
    "Work has already been scheduled for the provided campaign ids"
  const isSchedulingWork =
    successMessage === "Successfully initiated the campaign refill process"

  return { isNoWork, isAlreadyScheduled, isSchedulingWork, ...rest }
}
