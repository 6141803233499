/* eslint-disable no-console */
import { useEffect, useState } from "react"
import { matchPath, useLocation } from "react-router"

import { useAgentState } from "@/agent-state"
import { isInManualCall } from "@/agent-state/state-helpers"
import { useModal } from "@/components/core/Root/modal-root"
import { FinisheManualCallPrompt } from "@/components/modals/FinishManualCallPrompt"
import { useConnectActionsHook } from "@/hooks/connectActions"
import { useManualCallAtom } from "@/hooks/useManualCallAtom"
import { useSendCallReport } from "@/hooks/useSendCallReport"
import { contactStorageService } from "@/services/localStorageService"

export const useUnfinishedManualCallChecker = () => {
  const [inFinishing, setInFinishing] = useState(false)
  const { state } = useAgentState()
  const { showModal } = useModal()
  const inManualCall = isInManualCall(state)
  const location = useLocation()
  const { closeContact, hangUpPhoneCall } = useConnectActionsHook()
  const { resetInManualCall } = useManualCallAtom()

  // const timer = restoreTimer()
  const manualCallCase = contactStorageService.getManualCallCase()
  const sendCallReportMutation = useSendCallReport({
    currentCase: manualCallCase,
  })

  const inStudentDetail = Boolean(
    matchPath(
      "/campaigns/:campaignId/students/:studentName",
      location.pathname,
    ),
  )

  const unfinishedManualCallDetected =
    state.value !== "loading" &&
    inManualCall &&
    manualCallCase &&
    !inStudentDetail &&
    inFinishing === false // in case finish manual call multiple times

  const safeHangUp = async () => {
    if (state.matches("busy")) {
      try {
        await hangUpPhoneCall()
      } catch (error) {
        console.error(">>>> Error hangup phone call", error)
      }
    }
  }
  const safeCloseContact = async () => {
    try {
      await closeContact()
    } catch (error) {
      console.error(">>>> Error close contact", error)
    }
  }

  useEffect(() => {
    const checkUnifishedManualCall = async () => {
      if (unfinishedManualCallDetected) {
        setInFinishing(true)
        showModal((close) => {
          return (
            <FinisheManualCallPrompt onClose={close} isPending={inFinishing} />
          )
        })
        await safeHangUp()
        await sendCallReportMutation.mutateAsync({
          callReportType: "manual-leaving-view",
        })
        await safeCloseContact()
        resetInManualCall()
        setInFinishing(false)
      }
    }
    checkUnifishedManualCall()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unfinishedManualCallDetected, inFinishing])
}
