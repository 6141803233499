import { connectErrorHandler } from "@/helpers/error"
import { SentryLogger } from "@/helpers/sentryLogger"
import { contactStorageService } from "@/services/localStorageService"

import { autoAcceptTask, getNextContactId } from "./contact_helpers"
import {
  HandleAfterCallWorkInputType,
  HandleContactDestroyedInputType,
  HandleMissedContactInputType,
  InitContactInputType,
} from "./types"

const log = new SentryLogger()

const initNewContact = async ({ agentRef, contact }: InitContactInputType) => {
  const contactType = contact.getType()
  const contactState = contact.getState()

  const shouldAutoAcceptTask =
    contactType === connect.ContactType.TASK &&
    contactState.type === connect.ContactStateType.CONNECTING

  if (shouldAutoAcceptTask) {
    await autoAcceptTask(agentRef.current)
  }
}

const clearCurrentContact = async ({
  contact,
  isInbound,
  isTask,
}: {
  contact: connect.Contact
  isInbound?: boolean
  isTask?: boolean
}) => {
  const successMessage = isTask
    ? "Success closing task"
    : isInbound
    ? "Success closing inbound call"
    : "Success closing contact"

  const errorContext = isTask
    ? "Failure to clear contact after agent clicks end task"
    : isInbound
    ? "Failure to clear contact on missed inbound call"
    : "Failure to clear contact"

  return new Promise((resolve, reject) => {
    contact.clear({
      success: () => resolve(successMessage),
      failure: (err) => reject(connectErrorHandler(err, errorContext)),
    })
  })
}

const handleAfterCallWork =
  ({ agentRef }: HandleAfterCallWorkInputType) =>
  async (contact: connect.Contact) => {
    try {
      const shouldCloseTask = contact.getType() === connect.ContactType.TASK

      // Automatically close a task after the agent has clicked END TASK
      // because the call report is submitted in the voice call's ACW state
      if (shouldCloseTask) {
        await clearCurrentContact({ contact, isTask: true })
      }
    } catch (error) {
      const agent = agentRef.current

      log.error(error, {
        contact: contact.toSnapshot(),
        agent: agent?.toSnapshot(),
      })
    }
  }

const handleMissedContact =
  ({ agentRef }: HandleMissedContactInputType) =>
  async (contact: connect.Contact) => {
    try {
      const isInbound = contact.isInbound()
      const agentStatusName = agentRef.current?.getStatus().name
      const isMissedInboundCall =
        isInbound && agentStatusName === "FailedConnectAgent"

      // Close the inbound contact on a skipped inbound call
      // Fyi the missed call has an agent status name == MissedCallAgent
      if (isMissedInboundCall) {
        await clearCurrentContact({ contact, isInbound })
      }
    } catch (error) {
      const agent = agentRef.current

      log.error(error, {
        contact: contact.toSnapshot(),
        agent: agent?.toSnapshot(),
      })
    }
  }

const handleContactDestroyed =
  ({ agentRef, resetStateForNextContact }: HandleContactDestroyedInputType) =>
  (contact: connect.Contact) => {
    try {
      const agent = agentRef.current

      const nextContactId = getNextContactId({
        agent,
        destroyedContactId: contact?.contactId,
      })

      if (!nextContactId) {
        // Trigger loading state for the next task or inbound call
        // Delete the pseudo ACW state from local storage
        // Erase the call duration data and processing time in local storage
        resetStateForNextContact()

        // Remove ACW form data stored in local storage
        contactStorageService.removeCallReport()
      }
    } catch (error) {
      // contact is mistyped in Amazon Connect. It's not an instance of
      // connect.Contact but a Record<string, any>
      log.error(error, {
        contact,
        agent: agentRef.current?.toSnapshot(),
      })
    }
  }

export {
  handleAfterCallWork,
  handleContactDestroyed,
  handleMissedContact,
  initNewContact,
}
