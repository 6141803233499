import { useTranslation } from "react-i18next"

import { AgentMachineSnapshot } from "@/agent-state/machine-logic"
import Icon, { IconName } from "@/components/Icon"

type Props = {
  state: AgentMachineSnapshot
}

export function CallPanelStatusIcon({ state }: Props) {
  const { t } = useTranslation()

  const { statusIconName, translationKey } = getStatusAndIcon({
    state,
  })

  return (
    <>
      {statusIconName && <Icon name={statusIconName} size="xsm" />}
      {translationKey && (
        <p className="font-body-medium-bold" data-unmask>
          {t(translationKey)}
        </p>
      )}
    </>
  )
}

function getStatusAndIcon({ state }: Props): {
  statusIconName: IconName | undefined
  translationKey: string
} {
  const baseTranslationKey = "callPanel.status."

  const translationKey = ""
  const statusIconName: IconName | undefined = undefined

  if (
    state.matches({ busy: "outbound_ringing" }) ||
    state.matches({ busy: "inbound_ringing" })
  ) {
    return {
      translationKey: `${baseTranslationKey}connecting`,
      statusIconName: "dot-warning",
    }
  }

  if (state.matches({ busy: "no_call" })) {
    return {
      translationKey: "callPanel.report.title",
      statusIconName: "dot-primary",
    }
  }

  if (state.matches({ busy: "in_call" })) {
    return {
      translationKey: `${baseTranslationKey}connected`,
      statusIconName: "dot-success",
    }
  }

  if (state.matches({ busy: "after_call" })) {
    return {
      translationKey: `${baseTranslationKey}acw`,
      statusIconName: "dot-primary",
    }
  }

  if (state.matches({ busy: "error" })) {
    const errorKey = getErrorTranslationKey(state)

    return {
      translationKey: errorKey ? errorKey : `${baseTranslationKey}error`,
      statusIconName: "dot-error",
    }
  }

  // By default show no status message in the initializing state
  return {
    translationKey,
    statusIconName,
  }
}

function getErrorTranslationKey(state: Props["state"]): string | undefined {
  if (!state.matches({ busy: "error" })) {
    return
  }

  const baseTranslationKey = "callPanel.status."
  const isMissedTask = state.matches({ busy: { error: "missed_task" } })
  const isMissedCall = state.matches({ busy: { error: "missed_inbound_call" } })
  const isMissedCallAgentState = state.matches({
    busy: { error: "missed_call_agent_state" },
  }) // e.g. due to networking error
  // or due to a call getting 'stuck' for >30 seconds e.g. due to lack of microphone access.
  const isRejected = state.matches({ busy: { error: "rejected_call" } })
  const isSkipped = state.matches({ busy: { error: "rejected_task" } })

  if (isMissedTask) {
    return `${baseTranslationKey}missedTask`
  }

  if (isMissedCall) {
    return `${baseTranslationKey}missedCall`
  }

  if (isMissedCallAgentState) {
    return `${baseTranslationKey}missedCallAgentState`
  }

  if (isSkipped) {
    return `${baseTranslationKey}skipped`
  }

  if (isRejected) {
    return `${baseTranslationKey}rejectedCall`
  }

  return
}
