import { useAgentState } from "@/agent-state"
import { useCallDuration } from "@/hooks/callDuration"
import { useConnectActionsHook } from "@/hooks/connectActions"
import { useToast } from "@/hooks/toastHook"
import { useCallPanelLogger } from "@/hooks/useLogger"
import { CaseWithActivityFeed } from "@/pages/Campaigns/student-details/student-details-api"
import { contactStorageService } from "@/services/localStorageService"

import { useModal } from "../core/Root/modal-root"
import {
  DialogConfirmBackToCallMode,
  StatusUpdateModal,
} from "./callpanel-modals"
import { useErrorButtonsHook } from "./ErrorButtons/hook"

export function useCallPanelActions({
  phoneNumber,
}: {
  phoneNumber: string | null
}) {
  const { state } = useAgentState()
  const {
    answerPhoneCall,
    callPhoneNumber,
    closeContact,
    closeTask,
    hangUpPhoneCall,
    isMuted,
    isOnHold,
    manualCallPhoneNumber,
    rejectCall,
    toggleHoldCall,
    toggleMuteCall,
  } = useConnectActionsHook()
  const { clearTimestampsInLocalStorage, closeContactWithFallback } =
    useErrorButtonsHook()

  const { error: toastError } = useToast()
  const log = useCallPanelLogger()
  const { showModal } = useModal()

  const { send } = useAgentState()

  const { endTimer, startTimer } = useCallDuration()

  async function acceptInboundCall() {
    await wrapAction(async () => {
      await answerPhoneCall()
      startTimer()
    }, "errors.callPanel.answerCallError")
  }

  async function rejectInboundCall() {
    await wrapAction(async () => {
      await rejectCall()
      send({ type: "REJECT_INBOUND_CALL" })
    }, "errors.callPanel.skipCallError")
  }

  async function endCall() {
    await wrapAction(async () => {
      await hangUpPhoneCall()
      endTimer()
    }, "errors.callPanel.endCallError")
  }

  async function toggleHold() {
    await wrapAction(async () => {
      await toggleHoldCall()
    }, `errors.callPanel.${isOnHold ? "resumeCallError" : "holdCallError"}`)
  }

  async function toggleMute() {
    await wrapAction(async () => {
      toggleMuteCall()
    }, `errors.callPanel.${isMuted ? "unmuteCallError" : "muteCallError"}`)
  }

  async function call() {
    await wrapAction(async () => {
      if (!phoneNumber) {
        throw new Error("No phone number found")
      }
      await callPhoneNumber(phoneNumber)
      startTimer()
    }, "errors.callPanel.callError")
  }

  async function manualCall(queueARN: string, caseData: CaseWithActivityFeed) {
    wrapAction(async () => {
      if (!phoneNumber) {
        throw new Error("No phone number found")
      }
      if (!queueARN) {
        throw new Error("No queueARN provided")
      }

      // Turn a boolean true when the agent initiates a manual call;
      // use this boolean to decide in the iframe contact.onDestroy event if whe need to prevent the automatic
      // erasing of the contact state in local storage; e.g. if the manual call results in Pseudo ACW state
      // we need to preserve the state until the agent posts a call report
      contactStorageService.setManualCallCase(caseData)
      await manualCallPhoneNumber(phoneNumber, queueARN)
      startTimer()
    }, "errors.callPanel.callError")
  }

  async function addStatusInModal(caseInfo: CaseWithActivityFeed) {
    await showModal<boolean>((close) => (
      <StatusUpdateModal onClose={close} studentCase={caseInfo} />
    ))
    contactStorageService.removeCallReport()
  }

  function skip() {
    send({ type: "WONT_CALL" })
  }

  async function backToCallMode() {
    const confirm = await showModal<boolean>((close) => (
      <DialogConfirmBackToCallMode close={close} />
    ))
    if (confirm) {
      send({ type: "GO_BACK_TO_CALL_MODE" })
    }
  }

  async function endTaskError() {
    await wrapAction(async () => {
      clearTimestampsInLocalStorage()
      await closeTaskError()
      send({ type: "CLOSE_TASK_AFTER_ERROR" })
    }, "errors.callPanel.nextContactError")
  }

  async function closeTaskError() {
    if (
      state.matches({ busy: { error: "missed_inbound_call" } }) ||
      state.matches({ busy: { error: "missed_call_agent_state" } })
    ) {
      await closeContact()
    }
    if (
      state.matches({ busy: { error: "missed_task" } }) ||
      state.matches({ busy: { error: "rejected_task" } })
    ) {
      await closeTask()
      log.warn("Skipped task with impossible state - rejected task not closed.")
    }
    if (state.matches({ busy: { error: "internal_error" } })) {
      await closeContactWithFallback()
    }
  }

  /** Wrap actions with the same error handling, showing a toast message with a given error message */
  async function wrapAction(action: () => Promise<void>, errorMessage: string) {
    try {
      await action()
    } catch (err) {
      if (err instanceof Error) {
        log.error(err)
      }
      toastError(errorMessage)
    }
  }

  return {
    acceptInboundCall,
    backToCallMode,
    call,
    endCall,
    endTaskError,
    isMuted,
    isOnHold,
    manualCall,
    rejectInboundCall,
    skip,
    addStatusInModal,
    toggleHold,
    toggleMute,
  }
}
