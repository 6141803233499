import { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"

import { Button } from "@/components/ui/button"
import { cn } from "@/helpers/classNames"

import { useAnalytics, userEventsMap } from "../core/Root/analytics"
import { useTruncatedAnswerListHook } from "./hook"

interface Props {
  items: string[]
  question: string
}

/**
 * Component to display an array of student answers as a list.
 * It truncates the list after 3 lines and displays a "Show More" button to expand the list.
 * @param items - Array of string
 *
 * @returns ReactNode
 */
const TruncatedAnswerList: FunctionComponent<Props> = ({ items, question }) => {
  const { formattedItems, isOpen, listRef, setIsOpen, showButton } =
    useTruncatedAnswerListHook(items)
  const { t } = useTranslation()
  const { trackEvent } = useAnalytics()

  return (
    <div>
      <ul
        ref={listRef}
        className={cn(
          "font-body-medium-regular list-inside list-disc text-neutral-700",
          isOpen ? "overflow-y-auto" : "line-clamp-3",
        )}
      >
        {formattedItems.map((text, i) => (
          <li key={i}>{text}</li>
        ))}
      </ul>
      {showButton && (
        <Button
          variant="ghost"
          className="hover:bg-transparent font-label-small text-primary-300 hover:text-primary-400"
          type="button"
          size="xs"
          onClick={() => {
            setIsOpen((value) => !value)
            trackEvent(
              isOpen
                ? userEventsMap["questions/hide"]
                : userEventsMap["questions/show"],
              { question },
            )
          }}
        >
          {isOpen ? t("global.button.showLess") : t("global.button.showMore")}
        </Button>
      )}
    </div>
  )
}

export default TruncatedAnswerList
