import { useTimeout } from "react-use"

import { useAgentState } from "@/agent-state"
import EmptyQueueView from "@/components/EmptyQueueView"
import { config } from "@/config"

import { WaitingForTaskView } from "./loading-view"
import { RequestWorkView } from "./request-work"
import { useRequestWork } from "./request-work/hook"
import ResetCallViewOnQueueRefillError from "./reset_queue_refill"

export function AvailableView() {
  const {
    state: {
      context: { queueNames },
    },
  } = useAgentState()
  const delay = config.emptyQueueOffset
  const [hasDelayElapsed] = useTimeout(delay)

  if (!queueNames || queueNames.length === 0) {
    return <EmptyQueueView />
  }

  if (hasDelayElapsed()) {
    return <DelayedAvailableView />
  } else {
    return <WaitingForTaskView />
  }
}

function DelayedAvailableView() {
  const { isAlreadyScheduled, isError, isNoWork, isSchedulingWork } =
    useRequestWork()

  if (isSchedulingWork) return <RequestWorkView />

  if (isNoWork) return <EmptyQueueView />

  if (isAlreadyScheduled) {
    return (
      <ResetCallViewOnQueueRefillError
        errorContext={"queue-refill-already-scheduled"}
      />
    )
  }

  if (isError) {
    return (
      <ResetCallViewOnQueueRefillError errorContext={"queue-refill-error"} />
    )
  }

  return <WaitingForTaskView />
}
