import { atom } from "jotai"
import { atomWithStorage } from "jotai/utils"

import { CallDurationData } from "@/helpers/zodSchemas"
import { contactStorageService } from "@/services/localStorageService"
import { AuthenticationStatus, CCPMode, ModalMode } from "@/types/modes"

export enum UndocumentedAgentStateType {
  ERROR = "error",
  SYSTEM = "system",
}

export const cognitoAuthStatusAtom = atom<AuthenticationStatus>({
  current: "AUTHENTICATING",
})

export const ccpModeAtom = atom<CCPMode>({ current: "LOADING" })

export const modalModeAtom = atom<ModalMode>(null)

export const isMutedAtom = atom<boolean>(false)

export const callDurationDataAtom = atomWithStorage<CallDurationData | null>(
  contactStorageService.getCallDurationKey(),
  null,
  undefined,
  { getOnInit: true },
)
