import { useTranslation } from "react-i18next"
import { ChevronLeftIcon } from "lucide-react"

import { useAgentState } from "@/agent-state"
import { DelayedClickButton } from "@/components/DelayedButton"
import Icon from "@/components/Icon"
import { SingleClickButton } from "@/components/SingleClickButton"
import { Button } from "@/components/ui/button"
import { TooltipWrapper } from "@/components/ui/tooltip"
import { isNull } from "@/helpers/typeguards"
import { useLogger } from "@/hooks/useLogger"
import { CaseWithActivityFeed } from "@/pages/Campaigns/student-details/student-details-api"

import { useACWButtonsHook } from "./ACWButtons/hook"
import { useCallPanelActions } from "./callpanel-actions"
import { useGetEnabledCallConditions } from "./ManualCallButtons/hook"

type Props = {
  actions: ReturnType<typeof useCallPanelActions>
  acwButtonState: ReturnType<typeof useACWButtonsHook> & {
    isDisabled: boolean
    isManualCall?: boolean
  }
  campaignName: string | null
  caseData: CaseWithActivityFeed | null
}

export function CallPanelButtons({
  actions,
  acwButtonState,
  campaignName,
  caseData,
}: Props) {
  const {
    acceptInboundCall,
    addStatusInModal,
    backToCallMode,
    call,
    endCall,
    endTaskError,
    isMuted,
    isOnHold,
    manualCall,
    rejectInboundCall,
    skip,
    toggleHold,
    toggleMute,
  } = actions

  const log = useLogger()
  const { state } = useAgentState()

  if (state.matches("offline")) {
    if (isNull(campaignName) || isNull(caseData)) return null

    return (
      <ManualCallIdleButtons
        campaignName={campaignName}
        caseData={caseData}
        onAddStatus={addStatusInModal}
        onCall={manualCall}
      />
    )
  }

  if (
    state.matches({ busy: "pre_call" }) &&
    state.context.displayStatus === "online"
  ) {
    return <ConnectedTaskButtons onCall={call} onSkipCall={skip} />
  }

  if (state.matches({ busy: "no_call" })) {
    return (
      <SkipCallModeButtons
        isLoading={acwButtonState.isLoading}
        isDisabled={acwButtonState.isDisabled}
        onEndTask={() => acwButtonState.onClick(false)}
        onGoBack={backToCallMode}
      />
    )
  }

  if (state.matches({ busy: "outbound_ringing" })) {
    return <OutboundCallRingingButtons onHangUp={endCall} />
  }

  if (state.matches({ busy: "inbound_ringing" })) {
    return (
      <InboundCallRingingButtons
        onAccept={acceptInboundCall}
        onReject={rejectInboundCall}
      />
    )
  }

  if (state.matches({ busy: "in_call" })) {
    return (
      <InCallButtons
        isMuted={isMuted}
        isOnHold={isOnHold}
        onEndCall={endCall}
        onToggleHold={toggleHold}
        onToggleMute={toggleMute}
      />
    )
  }

  if (state.matches({ busy: "after_call" })) {
    return <ACWButtons {...acwButtonState} />
  }

  if (state.matches({ busy: "error" })) {
    return (
      <CallErrorButtons
        onEndTaskError={endTaskError}
        isNetworkError={state.matches({
          busy: { error: "missed_call_agent_state" },
        })}
      />
    )
  }

  // Monitor for the case that no buttons when agent displayStatus is online
  if (state.context.displayStatus === "online") {
    log.error("Invalid state for CallPanelButtons", { state })
  }

  return null
}

export function InboundCallRingingButtons({
  onAccept,
  onReject,
}: {
  onAccept: () => Promise<void>
  onReject: () => Promise<void>
}) {
  const { t } = useTranslation()

  return (
    <>
      <SingleClickButton variant="destructive" type="button" onClick={onReject}>
        <Icon name="end-call" />
        {t("global.button.skip")}
      </SingleClickButton>
      <SingleClickButton variant="success" type="button" onClick={onAccept}>
        <Icon name="accept-call" />
        {t("global.button.acceptInbound")}
      </SingleClickButton>
    </>
  )
}

export function OutboundCallRingingButtons({
  onHangUp,
}: {
  onHangUp: () => Promise<void>
}) {
  const { t } = useTranslation()

  return (
    <SingleClickButton variant="destructive" type="button" onClick={onHangUp}>
      <Icon name="end-call" />
      {t("global.button.endCall")}
    </SingleClickButton>
  )
}

export function InCallButtons({
  isMuted,
  isOnHold,
  onEndCall,
  onToggleHold,
  onToggleMute,
}: {
  isMuted: boolean
  isOnHold: boolean
  onEndCall: () => Promise<void>
  onToggleHold: () => Promise<void>
  onToggleMute: () => Promise<void>
}) {
  const { t } = useTranslation()

  return (
    <>
      <DelayedClickButton
        type="button"
        variant="outline"
        className="w-[126px]"
        onClick={onToggleHold}
      >
        <Icon name={isOnHold ? "resume-call" : "hold-call"} />
        {t(`global.button.${isOnHold ? "resume" : "hold"}`)}
      </DelayedClickButton>
      <Button
        type="button"
        variant="outline"
        className="w-[156px]"
        onClick={onToggleMute}
      >
        <Icon name={isMuted ? "unmute-call" : "mute-call"} />
        {t(`global.button.${isMuted ? "unmute" : "mute"}`)}
      </Button>
      <SingleClickButton
        variant="destructive"
        type="button"
        onClick={onEndCall}
      >
        <Icon name="end-call" />
        {t("global.button.endCall")}
      </SingleClickButton>
    </>
  )
}

export function ConnectedTaskButtons({
  onCall,
  onSkipCall,
}: {
  onCall: () => Promise<void>
  onSkipCall: () => void
}) {
  const { t } = useTranslation()

  return (
    <>
      <SingleClickButton type="button" variant="success" onClick={onCall}>
        <Icon name="accept-call" />
        {t("global.button.call")}
      </SingleClickButton>
      <Button variant="outline" type="button" onClick={onSkipCall}>
        <Icon name="end-call" />
        {t("global.button.wontCall")}
      </Button>
    </>
  )
}

export function SkipCallModeButtons({
  isDisabled,
  isLoading,
  onEndTask,
  onGoBack,
}: {
  isDisabled: boolean
  isLoading: boolean
  onEndTask: () => Promise<void>
  onGoBack: () => Promise<void>
}) {
  const { t } = useTranslation()

  return (
    <>
      <Button variant="outline" onClick={onGoBack}>
        <ChevronLeftIcon />
        {t("global.button.goBack")}
      </Button>
      <Button isDisabled={isDisabled} isLoading={isLoading} onClick={onEndTask}>
        {t("global.button.endTask")}
      </Button>
    </>
  )
}

export function ACWButtons({
  isDisabled,
  isLoading,
  isManualCall = false,
  onClick,
}: ReturnType<typeof useACWButtonsHook> & {
  isDisabled: boolean
  isManualCall?: boolean
}) {
  const { t } = useTranslation()

  return (
    <Button
      type="button"
      onClick={async () => onClick(true)}
      isDisabled={isDisabled}
      isLoading={isLoading}
    >
      {t(
        isManualCall
          ? "global.button.confirmAndReturn"
          : "global.button.endTask",
      )}
    </Button>
  )
}

export function CallErrorButtons({
  isNetworkError,
  onEndTaskError,
}: {
  isNetworkError: boolean
  onEndTaskError: () => Promise<void>
}) {
  const { t } = useTranslation()

  return (
    <Button type="button" onClick={onEndTaskError}>
      {isNetworkError ? t("global.button.close") : t("global.button.endTask")}
    </Button>
  )
}

export function ManualCallIdleButtons({
  campaignName,
  caseData,
  onAddStatus,
  onCall,
}: {
  campaignName: string
  caseData: CaseWithActivityFeed
  onAddStatus: (caseData: CaseWithActivityFeed) => Promise<void>
  onCall: (queueARN: string, caseData: CaseWithActivityFeed) => Promise<void>
}) {
  const { t } = useTranslation()
  const { fetchCampaignQuery, fetchQueueARNQuery } =
    useGetEnabledCallConditions(campaignName)
  const queueARN = fetchQueueARNQuery?.data?.data.queue_arn
  const campaignEnabled = fetchCampaignQuery?.data?.data.enabled || false
  const isLoading =
    fetchQueueARNQuery?.isLoading && fetchCampaignQuery?.isLoading

  const handleCall = async () => {
    if (!queueARN) return
    await onCall(queueARN, caseData)
  }
  const enableCall = campaignEnabled && queueARN
  const tooltip = campaignEnabled
    ? queueARN
      ? undefined
      : t("callPanel.reason.invalidQueueARN")
    : t("callPanel.reason.disabledCampaign")

  return (
    <>
      <Button onClick={() => onAddStatus(caseData)} variant="outline">
        {t("global.button.addStatus")}
      </Button>
      <TooltipWrapper tooltip={tooltip}>
        <SingleClickButton
          isLoading={isLoading}
          isDisabled={!enableCall}
          onClick={handleCall}
        >
          {t("global.button.call")}
        </SingleClickButton>
      </TooltipWrapper>
    </>
  )
}
