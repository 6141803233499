import { useTranslation } from "react-i18next"

import { useAgentState } from "@/agent-state"
import { useDisplayAgentStatus } from "@/agent-state/agent-status-hooks"
import { isHandlingV2Task } from "@/agent-state/state-helpers"
import { useConnectActionsHook } from "@/hooks/connectActions"
import { useContactDataHook } from "@/hooks/contactData"
import { useToast } from "@/hooks/toastHook"
import { useCallPanelLogger } from "@/hooks/useLogger"
import { useSendCallReport } from "@/hooks/useSendCallReport"
import * as connectPanelValue from "@/pages/CustomConnectPanel/value"

interface GoOfflineHookType {
  goOffline: () => Promise<void>
  isLoading: boolean
}

const useGoOfflineHook = (
  currentCase: connectPanelValue.Decoder.CaseAndActivityFeeds | null,
): GoOfflineHookType => {
  const { state } = useAgentState()
  const { toggleAgentStatus } = useDisplayAgentStatus()

  const log = useCallPanelLogger()
  const { error: toastError } = useToast()
  const { t } = useTranslation()

  const { closeContact, endTask } = useConnectActionsHook()

  const { isTask, isVoiceCallWithError, isVoiceContact } = useContactDataHook()

  const sendCallReportMutation = useSendCallReport({
    currentCase,
  })

  const handleSendSkipCallReport = async (): Promise<void> => {
    try {
      await sendCallReportMutation.mutateAsync({
        callReportType: "automatic-going-offline",
      })
    } catch (error) {
      log.error(error)
    }
  }

  const stopCurrentTask = async () => {
    try {
      await endTask()
    } catch (error) {
      log.error(error)
    }
  }

  const sendReportAndEndTask = async (): Promise<void> => {
    await handleSendSkipCallReport()
    await stopCurrentTask()
  }

  const goOffline = async (): Promise<void> => {
    try {
      if (isVoiceCallWithError) {
        await closeContact()
        await toggleAgentStatus("offline")
      } else if (isVoiceContact) {
        return toastError(t("global.modals.goOffline.callError"))
      } else if (isTask || isHandlingV2Task(state.context)) {
        await toggleAgentStatus("offline", { enqueueNextState: true })
        await sendReportAndEndTask()
      }
    } catch (error) {
      log.error(error)
    }
  }

  return { goOffline, isLoading: sendCallReportMutation.isPending }
}

export { useGoOfflineHook }
