import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useBlocker, useNavigate } from "react-router"
import { format } from "date-fns"

import { useAgentState } from "@/agent-state"
import { isInManualCall } from "@/agent-state/state-helpers"
import { useCallReportOptions } from "@/components/ACWForm/call-report-options"
import { CallReportStatelessForm } from "@/components/ACWForm/call-report-stateless-form"
import { useACWFormHook } from "@/components/ACWForm/hook"
import { ShowCallReportErrorToast } from "@/components/ACWForm/report-error-card"
import { useACWButtonsHook } from "@/components/CallPanel/ACWButtons/hook"
import { ShowErrorToast } from "@/components/CallPanel/call-error-card"
import { useCallPanelActions } from "@/components/CallPanel/callpanel-actions"
import { CallPanelButtons } from "@/components/CallPanel/callpanel-buttons"
import { CallPanelCard } from "@/components/CallPanel/callpanel-card"
import { CallPanelStatusIcon } from "@/components/CallPanel/callpanel-status-icon"
import { Timer } from "@/components/CallPanel/hook"
import { useCallViewHook } from "@/components/CallView/hook"
import { ProcessingTimeWrapper } from "@/components/CallView/processing-time-wrapper"
import { useModal } from "@/components/core/Root/modal-root"
import { Card } from "@/components/ui/card"
import { config } from "@/config"
import { isNull } from "@/helpers/typeguards"
import * as connectPanelValue from "@/pages/CustomConnectPanel/value"

import { CaseWithActivityFeed } from "../student-details-api"
import LeavingConfirmation from "./leaving-confirm-modal"

type Props = {
  campaignName: string
  caseData: CaseWithActivityFeed | null
  historyItem: connectPanelValue.ActivityFeedEntry.ActivityFeedEntryType | null
  phoneNumber: string
  timer: Timer | null
}
export function ManualCallView({
  campaignName,
  caseData,
  historyItem,
  phoneNumber,
  timer,
}: Props) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { state } = useAgentState()
  const { showModal } = useModal()
  const inManualCall = isInManualCall(state)
  const { contactIdForProcessingTime, form } = useCallViewHook({
    currentCase: caseData || null,
    studentProfile: null,
  })

  const actions = useCallPanelActions({ phoneNumber })

  const { isLoadingAgent, onSave } = useACWFormHook({
    currentCase: caseData || null,
  })

  const acwButtonState = useACWButtonsHook({
    currentCase: caseData || null,
    isInboundCall: false,
    timer,
  })

  const isBusy = state.matches("busy")

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (inManualCall) {
        e.preventDefault()
      }
    }
    window.addEventListener("beforeunload", handleBeforeUnload)

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const blocked = useBlocker(
    ({ currentLocation, nextLocation }) =>
      isBusy && currentLocation.pathname !== nextLocation.pathname,
  )

  useEffect(() => {
    const checkBlocked = async () => {
      if (blocked.state === "blocked") {
        const res = await showModal<boolean>((close) => (
          <LeavingConfirmation
            currentCase={caseData || null}
            onClose={close}
            timer={timer}
          />
        ))
        if (res) {
          // blocker's status is changed due to isBusy, so proceed() is not working here
          navigate(blocked.location?.pathname)
        } else {
          blocked.reset()
        }
      }
    }
    checkBlocked()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blocked, caseData, timer])

  const showAcwForm = isBusy // hidden by default
  const options = useCallReportOptions({
    callReportType: "attempted-call",
    latestRepeaterStatus: caseData?.case.latest_repeater_status || null,
    inManualCall: true,
    isInboundCall: false,
    isPseudoACW: state.matches({ busy: { after_call: "pseudo_acw" } }),
  })

  return (
    <div className="flex flex-col gap-4">
      <ShowErrorToast />
      <ShowCallReportErrorToast />
      <CallPanelCard
        phoneNumber={phoneNumber}
        campaignName={campaignName}
        buttons={
          config.enableManualCall && (
            <CallPanelButtons
              caseData={caseData}
              actions={actions}
              campaignName={campaignName}
              acwButtonState={{
                ...acwButtonState,
                isDisabled: !historyItem,
                isManualCall: true,
              }}
            />
          )
        }
        status={<CallPanelStatusIcon state={state} />}
        timer={timer}
      />
      {showAcwForm ? (
        <Card className="px-4 py-6">
          <CallReportStatelessForm
            form={form}
            isDisabled={false}
            isNewItem={isNull(historyItem)}
            isLoadingAgent={isLoadingAgent}
            onSave={onSave}
            title={t("callResult.title")}
            availableCallReportOptions={options}
          />
        </Card>
      ) : (
        <NextCallCard caseData={caseData} />
      )}
      {contactIdForProcessingTime && (
        <ProcessingTimeWrapper contactId={contactIdForProcessingTime} />
      )}
    </div>
  )
}

function NextCallCard({ caseData }: { caseData: CaseWithActivityFeed | null }) {
  const { t } = useTranslation()
  const noCall = caseData?.case?.no_call
  const taskContactIds = caseData?.case?.task_contact_ids || []
  const lastTaskContact = taskContactIds[taskContactIds.length - 1]
  const nextCallDate = lastTaskContact?.scheduled_at

  const nextCall =
    !noCall && nextCallDate ? format(nextCallDate, "yyyy/MM/dd HH:mm") : "---"

  return (
    <Card className="p-4">
      <h4 className="font-bold text-neutral-600">
        {t("caseHistory.activityFeed.nextCall")}
      </h4>
      <p className="text-neutral-600">{nextCall}</p>
    </Card>
  )
}
