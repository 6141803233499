import { useTranslation } from "react-i18next"
import { Link } from "react-router"
import { LoaderCircle, PlusIcon, RefreshCwIcon } from "lucide-react"

import { usePaginationParams } from "@/components/pagination/pagination-state"
import { AlertLoadingError } from "@/components/ui/alert"
import { Button, buttonVariants } from "@/components/ui/button"
import { usePermissions } from "@/hooks/agent-profile"

import { useFetchCampaigns } from "../shared/campaign-queries"
import { CampaignsTable, LoadingCampaignsTable } from "./campaigns-table"
import { CampaignSearchBar } from "./search-bar"
import { useCampaignSearchOptions } from "./search-options"

export function CampaignsListPage() {
  const { t } = useTranslation()
  const { canEditCampaigns } = usePermissions()

  const defaultPageSize = 20
  const { page, pageSize } = usePaginationParams(defaultPageSize)
  const searchState = useCampaignSearchOptions()

  const campaignListQuery = useFetchCampaigns({
    page,
    pageSize,
    search_query: searchState.searchOptions.search_query,
    enabled_campaigns_only: searchState.searchOptions.enabled_campaigns_only,
  })
  const { data, error, isError, isFetching, isLoading, refetch } =
    campaignListQuery

  const handleRefresh = () => {
    refetch()
  }

  if (isError) {
    return (
      <PageLayout
        content={
          <AlertLoadingError
            error={error}
            title="Unable to load campaign list"
          />
        }
      />
    )
  }

  const campaigns = data?.data.campaigns || []
  const metricsMeta = data?.data.metrics_metadata
  const total = data?.data.total || 0

  return (
    <PageLayout
      actions={
        <>
          <Button onClick={handleRefresh} variant="outline" size="icon">
            {isFetching ? (
              <LoaderCircle className="animate-spin" />
            ) : (
              <RefreshCwIcon className="size-5" />
            )}
          </Button>
          {canEditCampaigns && (
            <Link to="/campaigns/create" className={buttonVariants()}>
              <PlusIcon />
              {t("campaigns.list.create")}
            </Link>
          )}
        </>
      }
      content={
        isLoading ? (
          <LoadingCampaignsTable hasWriteAccess={canEditCampaigns} />
        ) : (
          <CampaignsTable
            campaigns={campaigns}
            metricsMeta={metricsMeta}
            hasWriteAccess={canEditCampaigns}
            onEdit={handleRefresh}
            page={page}
            pageSize={pageSize}
            total={total}
          />
        )
      }
      searchState={searchState}
    />
  )
}

function PageLayout({
  actions,
  content,
  searchState,
}: {
  actions?: React.ReactNode
  content: React.ReactNode
  searchState?: ReturnType<typeof useCampaignSearchOptions>
}) {
  const { t } = useTranslation()

  return (
    <main className="flex h-full flex-col gap-y-4 px-8 pt-10">
      <div className="container flex flex-col gap-4">
        <div className="flex items-center justify-between" data-unmask>
          <h1 className="font-h2-bold text-neutral-700">
            {t("campaigns.list.title")}
          </h1>
          <div className="flex items-center gap-4">{actions}</div>
        </div>
        {searchState && <CampaignSearchBar searchState={searchState} />}
        {content}
      </div>
    </main>
  )
}
