import { connectErrorHandler } from "@/helpers/error"
import { useToast } from "@/hooks/toastHook"
import { useCallPanelLogger } from "@/hooks/useLogger"

import { useAgentState } from "."

export function useDisplayAgentStatus() {
  const { agentRef, send, state } = useAgentState()
  const log = useCallPanelLogger()
  const { error: toastError } = useToast()

  /* Change agent Connect status and send the event to update the state machine `displayAgentStatus` */
  async function toggleAgentStatus(
    status: "offline" | "online",
    options?: ToggleOptions,
  ) {
    try {
      if (agentRef.current) {
        await toggleConnectAgentStatus(agentRef.current, status, options)
      }
      send({ type: "TOGGLE_DISPLAY_STATUS", status })
    } catch (error) {
      log.error(error, { status })
      toastError("Unable to change agent status")
    }
  }

  return { toggleAgentStatus, status: state.context.displayStatus }
}

type ToggleOptions = {
  enqueueNextState?: boolean
}

export function toggleConnectAgentStatus(
  agent: connect.Agent,
  status: "offline" | "online" | "pseudo_acw",
  options?: ToggleOptions,
) {
  const newStateType = getConnectStatusName(status)
  const newState = agent
    .getAgentStates()
    .find((state) => state.name === newStateType)
  if (!newState) throw new Error("Invalid agent state")

  return new Promise((resolve, reject) => {
    agent.setState(
      newState,
      {
        success: () => resolve(`Success: ${newStateType}`),
        failure: (err) =>
          reject(connectErrorHandler(err, "Failure to toggle agent status")),
      },
      options,
    )
  })
}

function getConnectStatusName(status: "offline" | "online" | "pseudo_acw") {
  switch (status) {
    case "offline":
      return "Offline"
    case "online":
      return "Available"
    // Custom state https://docs.aws.amazon.com/connect/latest/adminguide/agent-custom.html
    case "pseudo_acw":
      return "PseudoACW"
    default:
      throw new Error("Invalid status")
  }
}
