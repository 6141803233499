import * as callViewValue from "@/components/CallView/value"
import {
  CaseWithActivityFeed,
  caseWithActivityFeedDataSchema,
} from "@/pages/Campaigns/student-details/student-details-api"

import LocalStorageService from "./localStorageService"
/**
 * Service to handle CRUD operations in local storage for contact related data.
 */
class ContactStorageService extends LocalStorageService {
  private readonly CALL_REPORT_KEY = "call_report"
  private readonly PSEUDO_ACW_KEY = "pseudo_acw"
  private readonly SAVED_CALL_REPORT_KEY = "saved_call_report"
  private readonly CALL_DURATION_DATA = "call_duration_data"
  private readonly MANUAL_CALL = "manual_call"
  private readonly MANUAL_CALL_CASE = "manual_call_case"

  private static instance: ContactStorageService

  static getInstance(): ContactStorageService {
    if (!ContactStorageService.instance) {
      ContactStorageService.instance = new ContactStorageService()
    }

    return ContactStorageService.instance
  }

  // We split the call report into two different keys to avoid edge cases when updating and saving the call report
  // e.g. updated the form but didn't click save
  setCallReport({
    isSaved = false,
    value,
  }: {
    isSaved?: boolean
    value: callViewValue.Decoder.DecoderType
  }): void {
    const localStorageKey = isSaved
      ? this.SAVED_CALL_REPORT_KEY
      : this.CALL_REPORT_KEY

    return super.setItem(localStorageKey, value)
  }

  getCallReport(isSaved: boolean): unknown {
    const localStorageKey = isSaved
      ? this.SAVED_CALL_REPORT_KEY
      : this.CALL_REPORT_KEY

    return super.getItem(localStorageKey)
  }

  removeCallReport(): void {
    const keys = [this.CALL_REPORT_KEY, this.SAVED_CALL_REPORT_KEY]
    keys.forEach((key) => super.removeItem(key))
  }

  getManualCallCase(): CaseWithActivityFeed | null {
    const storedCase = super.getItem(this.MANUAL_CALL_CASE)
    const parsedCase = caseWithActivityFeedDataSchema.safeParse(storedCase)

    return parsedCase.success ? parsedCase.data : null
  }

  setManualCallCase(caseData: CaseWithActivityFeed): void {
    super.setItem(this.MANUAL_CALL_CASE, caseData)
  }

  removeManualCallCase(): void {
    super.removeItem(this.MANUAL_CALL_CASE)
  }

  clear(): void {
    // Will not remove the CALL_DURATION_DATA keys on log-out while using the
    // automatic call panel because the agent might still have an active contact when logging back in
    const keys = [this.CALL_REPORT_KEY, this.SAVED_CALL_REPORT_KEY]

    keys.forEach((key) => super.removeItem(key))
  }

  getPseudoACWKey(): string {
    return this.PSEUDO_ACW_KEY
  }

  getCallDurationKey(): string {
    return this.CALL_DURATION_DATA
  }

  getManualCallKey(): string {
    return this.MANUAL_CALL
  }

  getManualCallCaseKey(): string {
    return this.MANUAL_CALL_CASE
  }
}

const contactStorageService = ContactStorageService.getInstance()

export default contactStorageService
