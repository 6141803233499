import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"

interface SpeedControlProps {
  disabled?: boolean
  onChange: (value: string) => void
  value: string
}

const SPEED_OPTIONS = [
  { value: "0.5", label: "0.5x" },
  { value: "1", label: "1x" },
  { value: "1.5", label: "1.5x" },
  { value: "2", label: "2x" },
]

const SpeedControl = ({ disabled, onChange, value }: SpeedControlProps) => {
  return (
    <Select value={value} onValueChange={onChange} disabled={disabled}>
      <SelectTrigger className="w-[80px]" size="xs">
        <SelectValue placeholder="Speed" />
      </SelectTrigger>
      <SelectContent>
        {SPEED_OPTIONS.map((option) => (
          <SelectItem key={option.value} value={option.value} size="xs">
            {option.label}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}

export { SpeedControl }
