import { useForm, UseFormReturn } from "react-hook-form"
import { useMount } from "react-use"

import { useAgentState } from "@/agent-state"
import { useCurrentContact } from "@/agent-state/use-current-contact"
import { AgentProfile } from "@/agent-state/use-fetch-agent-profile"
import * as callViewValue from "@/components/CallView/value"
import { SentryLogger } from "@/helpers/sentryLogger"
import { formatStringValue as formatValue } from "@/helpers/valueFormat"
import * as connectPanelValue from "@/pages/CustomConnectPanel/value"
import { contactStorageService } from "@/services/localStorageService"

interface CallViewHookType {
  contactIdForProcessingTime: string | undefined
  form: UseFormReturn<callViewValue.Decoder.DecoderType>
  formattedTaskId: string
  formattedVoiceId: string
  studentId: string
}

const log = new SentryLogger()

const getStoredHistoryEntry = ({
  agent,
  agentProfile,
  currentCase,
  rawCallReport,
}: {
  agent: connect.Agent | null
  agentProfile: AgentProfile | null
  currentCase: connectPanelValue.Decoder.CaseAndActivityFeeds | null
  rawCallReport: unknown
}): connectPanelValue.ActivityFeedEntry.ActivityFeedEntryType | null => {
  try {
    const isInboundCall =
      agent?.getContacts(connect.ContactType.VOICE)[0]?.isInbound() ?? false

    const firstName = agentProfile?.identity_info.first_name ?? ""
    const lastName = agentProfile?.identity_info.last_name ?? ""
    const email = agentProfile?.email ?? ""

    // get the stored call report from local storage that was saved by the agent
    const storedCallReport =
      callViewValue.Decoder.schema.safeParse(rawCallReport)

    if (!storedCallReport.success) {
      return null
    }

    if (!storedCallReport.data.status) {
      return null
    }

    const formattedActivityEntry = connectPanelValue.ActivityFeedEntry.encode(
      storedCallReport.data,
      { firstName, lastName, email },
      currentCase?.case.campaign_id ?? "",
      isInboundCall ? "inbound" : "outbound",
    )

    return formattedActivityEntry
  } catch (err) {
    log.error(err, { agent: agent?.toSnapshot() })

    return null
  }
}

const useCallViewHook = ({
  currentCase,
  studentProfile,
}: {
  currentCase: connectPanelValue.Decoder.CaseAndActivityFeeds | null
  studentProfile: connectPanelValue.Decoder.StudentProfile | null
}): CallViewHookType => {
  const {
    agentRef,
    send,
    state: {
      context: { agentProfile },
    },
  } = useAgentState()
  const agent = agentRef.current
  const { getTaskId, getVoiceContact } = useCurrentContact()
  const taskId = getTaskId()
  const voiceId = getVoiceContact()?.getContactId()

  const rawCallReport = contactStorageService.getCallReport(true)

  useMount(() => {
    const formattedActivityEntry = getStoredHistoryEntry({
      agent,
      agentProfile,
      currentCase,
      rawCallReport,
    })
    if (formattedActivityEntry) {
      send({ type: "SAVE_CALL_REPORT", callReport: formattedActivityEntry })
    }
  })

  const form = useACWForm()

  const studentId = formatValue(
    currentCase?.case.student_id ?? studentProfile?.student_id,
  )

  const contactIdForProcessingTime = taskId || voiceId

  return {
    form,
    studentId,
    formattedTaskId: formatValue(taskId),
    formattedVoiceId: formatValue(voiceId),
    contactIdForProcessingTime,
  }
}

export function useACWForm() {
  const rawCallReport = contactStorageService.getCallReport(false)
  const defaultValues = callViewValue.Encoder.defaultValues(rawCallReport)

  return useForm<callViewValue.Decoder.DecoderType>({
    mode: "onBlur",
    shouldFocusError: false,
    defaultValues: defaultValues,
  })
}

export { useCallViewHook }
