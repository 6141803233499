import { MouseEvent, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  AlertCircle,
  AlignLeft,
  CirclePlay,
  Download,
  Headset,
  Loader,
  X,
} from "lucide-react"
import { z } from "zod"

import { PlaybackControls } from "@/components/RecordingPlayer/components/PlaybackControls"
import { ProgressBar } from "@/components/RecordingPlayer/components/ProgressBar"
import { SpeedControl } from "@/components/RecordingPlayer/components/SpeedControl"
import { TranscriptBar } from "@/components/RecordingPlayer/components/TranscriptBar"
import { TranscriptContainer } from "@/components/RecordingPlayer/components/TranscriptContainer"
import {
  useAudioPlayer,
  useFetchTranscript,
} from "@/components/RecordingPlayer/hooks/useAudioPlayer"
import { EventStatusPill } from "@/components/status-pill"
import { Button } from "@/components/ui/button"
import {
  SlidePanel,
  SlidePanelBody,
  SlidePanelContent,
  SlidePanelHeader,
} from "@/components/ui/slide-panel"
import { formatDateTime, formatReadableTime } from "@/helpers/dateFormat"
import { formatFullName } from "@/helpers/names"
import { useQueryAPI } from "@/hooks/query-api"

import { CallReport } from "./call-history-schemas"

const callDetailsSchema = z.object({
  data: z.object({
    audio_s3_path: z.string(),
    transcript_s3_path: z.string().nullable(),
  }),
})

// Simple trigger button that just calls the onPlay function
export const RecordingPlayButton = ({
  callReport,
  onPlay,
}: {
  callReport: CallReport
  onPlay: (callReport: CallReport) => void
}) => {
  if (!callReport.call_duration_seconds) {
    return "-"
  }

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    // Stop event propagation to prevent the drawer from closing
    e.stopPropagation()
    onPlay(callReport)
  }

  return (
    <div className="flex items-center gap-x-2">
      <Button variant="ghost" size={"xs"} onClick={handleClick}>
        <CirclePlay className="size-8 text-primary-300" />
      </Button>
      <span>{formatReadableTime(callReport.call_duration_seconds * 1000)}</span>
    </div>
  )
}

// Standalone audio player component using SlidePanel
export const AudioPlayer = ({
  callReport,
  onOpenChange,
  open = false,
}: {
  callReport: CallReport | null
  onOpenChange: (open: boolean) => void
  open: boolean
}) => {
  if (!callReport) return null

  return (
    <SlidePanel
      open={open}
      onOpenChange={onOpenChange}
      direction="bottom"
      variant="player"
      withPortal={false}
    >
      <SlidePanelContent>
        <div className="fixed right-2 top-2">
          <Button
            variant="ghost"
            size={"xs"}
            onClick={() => onOpenChange(false)}
          >
            <X className="size-4" />
          </Button>
        </div>
        <RecordingPlayer callReport={callReport} />
      </SlidePanelContent>
    </SlidePanel>
  )
}

// The actual player component that shows in the panel
export const RecordingPlayer = ({ callReport }: { callReport: CallReport }) => {
  const { t } = useTranslation()
  const [isTranscriptOpen, setIsTranscriptOpen] = useState(false)
  const {
    data: recordingData,
    isError,
    isLoading,
  } = useQueryAPI({
    apiEndPoint: "/ai-insights/call-details",
    params: {
      call_contact_id: callReport.call_contact_id,
      get_audio: true,
      get_transcript: true,
    },
    schema: callDetailsSchema,
    options: {
      enabled: true, // Auto-fetch when component mounts
    },
  })

  const { controls, playerState } = useAudioPlayer({
    audioUrl: recordingData?.data?.audio_s3_path ?? "",
  })
  const { transcriptData } = useFetchTranscript({
    transcriptUrl: recordingData?.data?.transcript_s3_path ?? "",
  })

  const studentName = formatFullName(
    callReport.first_name,
    callReport.last_name,
  )
  const agentName = formatFullName(
    callReport.agent_info.first_name,
    callReport.agent_info.last_name,
  )
  const callDate = formatDateTime(new Date(callReport.reported_at))

  // Reset player when changing calls
  useEffect(() => {
    if (playerState.isPlaying) {
      controls.togglePlay()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callReport.call_contact_id])

  const disabledControls = playerState.status !== "ready"

  return (
    <>
      <SlidePanelBody className="p-4">
        <div className="space-between flex">
          <div className="flex w-[300px] flex-col gap-y-2">
            <EventStatusPill value={callReport.status} variant="full" />
            <div className="flex justify-between">
              <div className="flex flex-col gap-y-1">
                <div className="font-bold">{studentName}</div>
                <div className="flex items-center gap-x-2 text-xs text-neutral-500">
                  <Headset className="size-4" />
                  {agentName}
                </div>
                <div className="text-xs text-neutral-500">{callDate}</div>
              </div>
              <div className="flex items-center">
                {isError || isLoading ? (
                  <span className="cursor-not-allowed opacity-50">
                    <Download className="size-8 text-neutral-400" />
                  </span>
                ) : (
                  <a href={recordingData?.data?.audio_s3_path} download>
                    <Download className="size-8 text-primary-300" />
                  </a>
                )}
              </div>
            </div>
          </div>
          <div className="p-x-20 flex flex-1 justify-center px-32">
            {isLoading ? (
              <div className="mt-8 flex justify-center">
                <Loader className="animate-spin" />
              </div>
            ) : (
              <div className="w-full space-y-2">
                <div className="flex items-center justify-center">
                  <div className="flex items-center gap-4">
                    <SpeedControl
                      disabled={disabledControls}
                      value={playerState.speed.toString()}
                      onChange={(value: string) =>
                        controls.setSpeed(Number(value))
                      }
                    />
                    <PlaybackControls
                      disabled={disabledControls}
                      isPlaying={playerState.isPlaying}
                      onPlayPause={controls.togglePlay}
                      onSkipForward={() => controls.skip(10)}
                      onSkipBackward={() => controls.skip(-10)}
                    />
                    {transcriptData?.Transcript && (
                      <Button
                        variant="outline"
                        onClick={() => setIsTranscriptOpen(!isTranscriptOpen)}
                        className="rounded px-2 py-1 text-xs text-muted-foreground hover:bg-accent"
                      >
                        <AlignLeft className="size-4" />
                        {t("callHistory.recording.transcript.toggle")}
                      </Button>
                    )}
                  </div>
                </div>
                <ProgressBar
                  currentTime={playerState.currentTime}
                  duration={playerState.duration}
                  onSeek={controls.seek}
                />
                {isError && (
                  <div className="mt-2 flex w-full items-center justify-center gap-2 rounded p-2 text-sm">
                    <AlertCircle className="size-4" />
                    <span>{t("callHistory.recording.retrieveFailure")}</span>
                  </div>
                )}
                {transcriptData?.Transcript && (
                  <TranscriptBar transcript={transcriptData.Transcript} />
                )}
              </div>
            )}
          </div>
        </div>
      </SlidePanelBody>

      {/* Transcript panel slides from the right */}
      <SlidePanel
        open={isTranscriptOpen}
        onOpenChange={setIsTranscriptOpen}
        direction="right"
        variant="transcript"
      >
        <SlidePanelContent>
          <SlidePanelHeader
            title={t("callHistory.recording.transcript.title")}
          />
          <SlidePanelBody>
            <TranscriptContainer
              transcript={transcriptData?.Transcript ?? []}
              isVisible={isTranscriptOpen}
              currentTime={playerState.currentTime}
              onSeek={controls.seek}
            />
          </SlidePanelBody>
        </SlidePanelContent>
      </SlidePanel>
    </>
  )
}
