import { useState } from "react"
import { useTranslation } from "react-i18next"
import { format } from "date-fns"

import { ColumnGroupDef, DashboardTable } from "@/components/DashboardTable"
import { PaginationState } from "@/components/pagination/pagination-state"
import { EventStatusPill } from "@/components/status-pill"
import { TableLink } from "@/components/table-helpers"
import { CopyIdButton } from "@/components/ui/copy-id-button"
import { formatFullName } from "@/helpers/names"

import { CallReport } from "./call-history-schemas"
import { AudioPlayer, RecordingPlayButton } from "./recording-playback"

type Props = PaginationState & {
  callReports: CallReport[]
  isLoading?: boolean
}
export function CallHistoryTable({ callReports, ...otherProps }: Props) {
  const columnGroups = useColumns()
  const [panelOpen, setPanelOpen] = useState(false)
  const [selectedCall, setSelectedCall] = useState<CallReport | null>(null)

  const handlePlayClick = (callReport: CallReport) => {
    setSelectedCall(callReport)
    if (!panelOpen) {
      setPanelOpen(true)
    }
  }

  const handleOpenChange = (open: boolean) => {
    setPanelOpen(open)
    if (!open) {
      setSelectedCall(null)
    }
  }

  return (
    <>
      <div className={panelOpen ? "mb-40" : "mb-20"}>
        <DashboardTable
          columnGroups={columnGroups(handlePlayClick)}
          items={callReports}
          getItemId={(callReport) =>
            callReport.group_id + callReport.campaign_id + callReport.student_id
          }
          {...otherProps}
        />
      </div>

      <AudioPlayer
        open={panelOpen}
        onOpenChange={handleOpenChange}
        callReport={selectedCall}
      />
    </>
  )
}

function useColumns() {
  const { t } = useTranslation()

  return (handlePlayClick: (callReport: CallReport) => void) => {
    const colGroups: ColumnGroupDef<CallReport>[] = [
      {
        header: t("callHistory.duration"),
        cols: [
          {
            id: "duration",
            cell: (callReport) => (
              <RecordingPlayButton
                callReport={callReport}
                onPlay={handlePlayClick}
              />
            ),
          },
        ],
      },
      {
        header: t("campaigns.details.students.name"),
        cols: [
          {
            id: "name",
            cell: (callReport) => (
              <TableLink
                to={`/campaigns/${callReport.campaign_id}/students/${callReport.student_id}`}
              >
                {formatFullName(callReport.first_name, callReport.last_name)}
              </TableLink>
            ),
          },
        ],
      },
      {
        header: t("caseHistory.entorId"),
        cols: [
          {
            id: "legacy_id",
            className: "min-w-32",
            cell: (callReport) => (
              <CopyIdButton
                className="w-full"
                uuid={callReport.legacy_id.toString()}
              />
            ),
          },
        ],
      },
      // {
      //   header: t("campaigns.preview.table.phoneNumber"),
      //   cols: [
      //     {
      //       id: "phone_number",
      //       cell: (callReport) => <>{callReport.phone_number}</>,
      //     },
      //   ],
      // },
      {
        header: t("caseHistory.activityFeed.status"),
        cols: [
          {
            id: "status",
            cell: (callReport) => <EventStatusPill value={callReport.status} />,
          },
        ],
      },
      {
        header: t("caseHistory.campaign"),
        cols: [
          {
            id: "campaign_id",
            cell: (callReport) => <>{callReport.campaign_id}</>,
          },
        ],
      },
      {
        header: t("caseHistory.activityFeed.lastUpdate"),
        cols: [
          {
            id: "reported_at",
            cell: (callReport) => (
              <>
                {format(new Date(callReport.reported_at), "yyyy/MM/dd HH:mm")}
              </>
            ),
          },
        ],
      },
      {
        header: t("caseHistory.agentName"),
        cols: [
          {
            id: "agent_name",
            cell: (callReport) => (
              <>
                {formatFullName(
                  callReport.agent_info.first_name,
                  callReport.agent_info.last_name,
                )}
              </>
            ),
          },
        ],
      },
      {
        header: t("caseHistory.activityFeed.callType.label"),
        cols: [
          {
            id: "call_type",
            cell: (callReport) => (
              <>
                {t(
                  `caseHistory.activityFeed.callType.values.${
                    callReport.call_type || "noCall"
                  }`,
                )}
              </>
            ),
          },
        ],
      },
    ]

    return colGroups
  }
}
