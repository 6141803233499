import {
  createContext,
  HTMLAttributes,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react"
import { createPortal } from "react-dom"
import { cva, type VariantProps } from "class-variance-authority"
import { X } from "lucide-react"

import { Button } from "@/components/ui/button"
import { cn } from "@/helpers/classNames"

// ==== Slide Panel Types ====
export type SlidePanelDirection = "bottom" | "left" | "right" | "top"
export type SlidePanelVariant = "default" | "player" | "transcript"

// SlidePanel Context
type SlidePanelContextValue = {
  direction: SlidePanelDirection
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  variant: SlidePanelVariant
}

const SlidePanelContext = createContext<SlidePanelContextValue | undefined>(
  undefined,
)

// Hook to use within SlidePanel children
// eslint-disable-next-line react-refresh/only-export-components
export const useSlidePanelContext = () => {
  const context = useContext(SlidePanelContext)
  if (!context) {
    throw new Error("useSlidePanelContext must be used within a SlidePanel")
  }

  return context
}

// ==== Root Component ====
interface SlidePanelProps {
  children: ReactNode
  className?: string
  direction?: SlidePanelDirection
  onOpenChange?: (open: boolean) => void
  open?: boolean
  variant?: SlidePanelVariant
  withPortal?: boolean
}

export function SlidePanel({
  children,
  className: _,
  direction = "right",
  onOpenChange,
  open = false,
  variant = "default",
  withPortal = true,
}: SlidePanelProps) {
  const [isOpen, setIsOpen] = useState(open)

  // Sync with external open state
  useEffect(() => {
    setIsOpen(open)
  }, [open])

  // Notify parent of changes
  const updateOpenState = (newState: boolean) => {
    setIsOpen(newState)
    onOpenChange?.(newState)
  }

  const contextValue = {
    isOpen,
    setIsOpen: updateOpenState,
    direction,
    variant,
  }

  const content = (
    <SlidePanelContext.Provider value={contextValue}>
      {children}
    </SlidePanelContext.Provider>
  )

  // Use portal if requested
  if (withPortal) {
    // Only create portal if DOM is available (client-side)
    return typeof document !== "undefined"
      ? createPortal(content, document.body)
      : null
  }

  return content
}

// ==== Content Component ====
const slidePanelContentVariants = cva(
  "z-50 overflow-auto border bg-background transition-transform duration-300 ease-in-out",
  {
    variants: {
      direction: {
        right:
          "right-0 h-[calc(100vh-8rem)] w-1/3 translate-x-full rounded-l-lg",
        left: "left-0 h-[calc(100vh-8rem)] w-1/3 -translate-x-full rounded-r-lg",
        bottom: "inset-x-0 bottom-0 h-auto translate-y-full rounded-t-lg",
        top: "inset-x-0 top-0 h-auto -translate-y-full rounded-b-lg",
      },
      variant: {
        transcript: "fixed bottom-40 right-0 h-[calc(100vh-16rem)] w-1/3",
        player: "fixed bottom-0 left-72 right-0 mx-2",
        default: "fixed",
      },
      isOpen: {
        true: "",
        false: "",
      },
    },
    compoundVariants: [
      {
        isOpen: true,
        direction: ["right", "left"],
        className: "translate-x-0",
      },
      {
        isOpen: true,
        direction: ["top", "bottom"],
        className: "translate-y-0",
      },
    ],
    defaultVariants: {
      direction: "right",
      variant: "default",
      isOpen: false,
    },
  },
)

interface SlidePanelContentProps
  extends HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof slidePanelContentVariants> {
  children: ReactNode
  className?: string
}

export function SlidePanelContent({
  children,
  className,
  ...props
}: SlidePanelContentProps) {
  const { direction, isOpen, variant } = useSlidePanelContext()

  return (
    <>
      <div
        className={cn(
          slidePanelContentVariants({
            direction,
            variant,
            isOpen,
          }),
          className,
        )}
        {...props}
      >
        {children}
      </div>
    </>
  )
}

// ==== Header Component ====
interface SlidePanelHeaderProps extends HTMLAttributes<HTMLDivElement> {
  className?: string
  showCloseButton?: boolean
  title?: string
}

export function SlidePanelHeader({
  children,
  className,
  showCloseButton = true,
  title,
  ...props
}: SlidePanelHeaderProps) {
  const { setIsOpen } = useSlidePanelContext()

  return (
    <div
      className={cn(
        "flex items-center justify-between border-b px-4 py-2",
        className,
      )}
      {...props}
    >
      {title && <h2 className="text-lg font-semibold">{title}</h2>}
      {showCloseButton && (
        <Button
          variant="ghost"
          size="icon"
          className="ml-auto"
          onClick={() => setIsOpen(false)}
        >
          <X className="size-5" />
        </Button>
      )}
    </div>
  )
}

// ==== Body Component ====
interface SlidePanelBodyProps extends HTMLAttributes<HTMLDivElement> {
  className?: string
}

export function SlidePanelBody({
  children,
  className,
  ...props
}: SlidePanelBodyProps) {
  return (
    <div className={cn("flex-1 overflow-auto p-4", className)} {...props}>
      {children}
    </div>
  )
}
