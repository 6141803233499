import { useTranslation } from "react-i18next"
import { PhoneCallIcon } from "lucide-react"

import Icon from "@/components/Icon"
import ProgressBar from "@/components/ProgressBar"
import { useI18n } from "@/helpers/i18n"

type Props = {
  description?: React.ReactNode
  icon: React.ReactNode
  title: React.ReactNode
}

export function LoadingCallView({ description, icon, title }: Props) {
  return (
    <div
      className="flex size-full flex-col items-center justify-center gap-y-4 px-34.5"
      data-unmask
      data-testid="call-view-loading"
    >
      {icon}
      <h4 className="font-h4-bold text-neutral-700">{title}</h4>
      {description && <p>{description}</p>}
      <div className="flex w-[360px] items-center justify-center">
        <ProgressBar />
      </div>
    </div>
  )
}

export function WaitingForTaskView() {
  const { t } = useTranslation()

  return (
    <LoadingCallView
      icon={<Icon name="folder-empty" size="xl" />}
      title={t("callPanel.loading.waitingForTask")}
    />
  )
}

export function LoadingTaskOutboundView() {
  const { t } = useTranslation()

  return (
    <LoadingCallView
      icon={<Icon name="folder-empty" size="xl" />}
      title={t("callPanel.loading.waitingForTask")}
      description={t("callPanel.loading.fetchingData")}
    />
  )
}

export function LoadingTaskInboundView({
  isInterrupted = false,
}: {
  isInterrupted?: boolean
}) {
  const { t, tMap } = useI18n()

  return (
    <LoadingCallView
      icon={
        <div>
          <PhoneCallIcon className="size-20 text-primary-200" />
        </div>
      }
      title={t("callPanel.loading.inboundCall")}
      description={
        isInterrupted
          ? tMap("callPanel.loading.interruptedByInboundCall", (text, i) => (
              <p className="text-center" key={i}>
                {text}
              </p>
            ))
          : undefined
      }
    />
  )
}
