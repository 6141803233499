import { useAgentState } from "."
import { isHandlingV2Task } from "./state-helpers"

export function useCurrentContact() {
  const { agentRef, state } = useAgentState()

  function getVoiceContact() {
    return (
      agentRef.current?.getContacts(connect.ContactType.VOICE).at(-1) || null
    )
  }

  function getConnectTaskContact() {
    return (
      agentRef.current?.getContacts(connect.ContactType.TASK).at(-1) || null
    )
  }

  function getTaskId() {
    const taskId = isHandlingV2Task(state.context)
      ? state.context.taskMeta?.taskId
      : getConnectTaskContact()?.getContactId()

    return taskId || null
  }

  function getCurrentContact() {
    return getVoiceContact() || getConnectTaskContact()
  }

  return {
    getVoiceContact,
    getTaskId,
    getCurrentContact,
  }
}

export function useCurrentContactData() {
  const { getCurrentContact } = useCurrentContact()
  const contact = getCurrentContact()

  return {
    currentContactType: contact?.getType() || null,
    currentContactStatusType: contact?.getStatus()?.type || null,
  }
}
