import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import { formatTime } from "@/lib/formatTime"
import { cn } from "@/lib/utils"

interface TranscriptContainerProps {
  currentTime: number
  isVisible: boolean
  onSeek: (time: number) => void
  transcript: {
    BeginOffsetMillis: number
    Content: string
    EndOffsetMillis: number
    Id: string
    LoudnessScore: (number | null)[]
    ParticipantId: "AGENT" | "CUSTOMER"
    Sentiment: "NEGATIVE" | "NEUTRAL" | "POSITIVE"
  }[]
}

export function TranscriptContainer({
  currentTime,
  isVisible,
  onSeek,
  transcript,
}: TranscriptContainerProps) {
  const { t } = useTranslation()
  const [autoScroll, setAutoScroll] = useState(true)
  const containerRef = useRef<HTMLDivElement>(null)
  const activeLineRef = useRef<HTMLDivElement>(null)

  // Find the currently active segment
  const activeSegment = transcript.find(
    (segment) =>
      currentTime >= segment.BeginOffsetMillis / 1000 &&
      currentTime <= segment.EndOffsetMillis / 1000,
  )

  // Auto-scroll effect
  useEffect(() => {
    if (autoScroll && activeLineRef.current && containerRef.current) {
      activeLineRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      })
    }
  }, [activeSegment?.Id, autoScroll])

  const handleSegmentClick = (beginTime: number) => {
    onSeek(beginTime / 1000)
  }

  if (!isVisible) return null

  return (
    <div className="relative">
      <div className="sticky top-0 z-10 bg-background p-2">
        <div className="flex items-center justify-end gap-2">
          <label className="text-sm text-muted-foreground">
            {t("callHistory.recording.transcript.autoScroll")}
          </label>
          <input
            type="checkbox"
            checked={autoScroll}
            onChange={(e) => setAutoScroll(e.target.checked)}
            className="border-gray-300 size-4 rounded"
          />
        </div>
      </div>
      <div ref={containerRef} className="min-h-[400px] overflow-y-auto px-4">
        {transcript.map((segment) => (
          <div
            key={segment.Id}
            ref={segment.Id === activeSegment?.Id ? activeLineRef : null}
            onClick={() => handleSegmentClick(segment.BeginOffsetMillis)}
            className={cn(
              "mb-4 grid cursor-pointer grid-cols-[80px_1fr] gap-2 rounded-md p-2 transition-colors hover:bg-accent/50",
              segment.Id === activeSegment?.Id && "bg-accent",
            )}
          >
            <div className="text-sm text-muted-foreground">
              {formatTime(segment.BeginOffsetMillis / 1000)}
            </div>
            <div className="space-y-1">
              <div
                className={cn(
                  "font-semibold",
                  segment.ParticipantId === "AGENT"
                    ? "text-blue-600"
                    : "text-green-600",
                )}
              >
                {segment.ParticipantId === "AGENT"
                  ? t("callHistory.role.agent")
                  : t("callHistory.role.student")}
              </div>
              <div className="text-foreground">{segment.Content}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
