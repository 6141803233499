import { useTranslation } from "react-i18next"

import { MultiCombobox } from "@/components/ui/multi-combobox"
import { useFetchCampaigns } from "@/pages/Campaigns/shared/campaign-queries"

import { LoadingFilter } from "../../Campaigns/pickers/generic-filter-picker"

type Props = {
  allowReset?: boolean
  onChange: (values: string[]) => void
  values: string[]
}

export function CampaignPicker({ allowReset, onChange, values }: Props) {
  const { t } = useTranslation()
  const { data, isLoading } = useFetchCampaigns({
    page: 1,
    pageSize: 1000,
    enabled_campaigns_only: false,
    search_query: "",
  })

  if (isLoading) return <LoadingFilter />

  const options =
    data?.data.campaigns.map((campaign) => ({
      value: campaign.campaign_id,
      label: campaign.campaign_id,
    })) || []

  return (
    <MultiCombobox
      allowReset={allowReset}
      values={values}
      onChange={onChange}
      options={options}
      placeholder={t("callHistory.campaignsFilterPlaceholder")}
      size="md"
    />
  )
}
