import { Status, statusMap } from "@repo/core/models"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "@/helpers/classNames"
import { useCallStatusLabel } from "@/hooks/call-statuses-context"

// Define the pill variants using CVA
const pillVariants = cva(
  "font-label-medium block rounded-full px-4 py-1 text-center font-bold text-neutral-600",
  {
    variants: {
      size: {
        fit: "max-w-fit",
        full: "w-full",
      },
      status: {
        success: "bg-success-100",
        warning: "bg-warning-200",
        danger: "bg-danger-100",
        default: "bg-primary-100",
      },
    },
    defaultVariants: {
      size: "fit",
      status: "default",
    },
  },
)

// Get the appropriate status variant based on the Status enum
function getStatusVariant(
  status: Status,
): VariantProps<typeof pillVariants>["status"] {
  switch (status) {
    case statusMap.TentativeEntry:
    case statusMap.ConfirmedEntry:
    case statusMap.ConfirmedEntryByAgent:
      return "success"
    case statusMap.InboundMissedCall:
    case statusMap.CallLaterAtGivenTime:
    case statusMap.CallAgainUnsuitableDates:
    case statusMap.CallerTemporarilyUnavailable:
    case statusMap.SendEmailCallLater:
      return "warning"
    case statusMap.GlobalNoCall:
    case statusMap.RejectInstantly:
    case statusMap.RejectAfterPitch:
    case statusMap.RejectUnsuitableDates:
    case statusMap.ConsecutiveMissedCalls:
    case statusMap.Cancelled:
    case statusMap.AlreadyRegistered:
      return "danger"
    default:
      return "default"
  }
}

type EventStatusPillProps = {
  className?: string
  value: Status
  variant?: "fit" | "full"
}

type CurrentStatusPillProps = {
  className?: string
  current: Status
  latestActionTaken: Status | null
  variant?: "fit" | "full"
}

/** Pill to show a given status from an activity feed */
export function EventStatusPill({
  className,
  value,
  variant = "fit",
}: EventStatusPillProps) {
  const getLabel = useCallStatusLabel()

  return (
    <span
      className={cn(
        pillVariants({
          size: variant,
          status: getStatusVariant(value),
          className,
        }),
      )}
      data-unmask
    >
      {getLabel(value)}
    </span>
  )
}

/** Specific Pill component to show the current status of a student, handling the `Leave as it` case */
export function CurrentStatusPill({
  className,
  current,
  latestActionTaken,
  variant = "fit",
}: CurrentStatusPillProps) {
  const getLabel = useCallStatusLabel()
  const statusToUse = latestActionTaken || current

  return (
    <span
      className={cn(
        pillVariants({
          size: variant,
          status: getStatusVariant(statusToUse),
          className,
        }),
      )}
      data-unmask
    >
      {current === latestActionTaken || !latestActionTaken ? (
        getLabel(current)
      ) : (
        <>
          {getLabel(latestActionTaken)}
          <span className="ml-2 font-normal">({getLabel(current)})</span>
        </>
      )}
    </span>
  )
}
