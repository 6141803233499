import { FunctionComponent, memo } from "react"

import MicrophoneErrorModal from "@/components/modals/MicrophoneErrorModal"
import { cn } from "@/helpers/classNames"
import { connectLoginUrl } from "@/hooks/auth"

import { useConnectCCPHook } from "./hook"

const styles = {
  wrapper: "flex flex-col gap-y-4",
  container: (isHidden: boolean | undefined) => {
    return cn(["h-[600px] w-full min-w-[400px]", isHidden && "hidden"])
  },
  hiddenIframeContainer: "hidden",
  hiddenFormContainer: "hidden",
}

const ConnectCCP: FunctionComponent = () => {
  const { connectMode, hiddenFormRef } = useConnectCCPHook()

  return (
    <div className={styles.wrapper}>
      <MicrophoneErrorModal />
      <div
        id="connect-iframe-container"
        style={{ display: "none", height: "500px" }}
      ></div>
      <div id="loginFrm_div" className={styles.hiddenFormContainer}>
        <form
          ref={hiddenFormRef}
          id="login_Frm"
          method="POST"
          target="hidden_iframe"
          action={connectLoginUrl}
          onSubmit={() => undefined}
        >
          <input
            type="text"
            name="credentials"
            id="credentials"
            value={JSON.stringify(connectMode.state.credentials)}
            onChange={() => undefined}
          />
        </form>
        {/* Following iframe used only as a target of the current hidden form */}
        <iframe id="hidden_iframe" name="hidden_iframe"></iframe>
      </div>
    </div>
  )
}

const MemoizedConnectCCP = memo(ConnectCCP)

export default MemoizedConnectCCP
