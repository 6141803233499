import { useLocation, useMatch, useNavigate } from "react-router"
import { useSetAtom } from "jotai"

import { useAgentState } from "@/agent-state"
import { useDisplayAgentStatus } from "@/agent-state/agent-status-hooks"
import { isInManualCall } from "@/agent-state/state-helpers"
import { useAnalytics, userEventsMap } from "@/components/core/Root/analytics"
import { routesConfig } from "@/config/routes"
import { modalModeAtom } from "@/helpers/atoms"
import { useLogger } from "@/hooks/useLogger"
import { useManualCallAtom } from "@/hooks/useManualCallAtom"

import { useModal } from "../core/Root/modal-root"
import { DialogGoOfflineWithContact } from "./dialog-go-offline-with-contact"
import { useGoOfflineHook } from "./go-offline-with-contact"

export function useAgentStatusHook() {
  const location = useLocation()
  const matchStudentDetailsPage = useMatch(
    "/campaigns/:campaignId/students/:studentId",
  )
  const navigate = useNavigate()
  const track = useTrackAgentStatusChange()
  const log = useLogger()
  const { showModal } = useModal()
  const { agentRef, state } = useAgentState()
  const { toggleAgentStatus } = useDisplayAgentStatus()
  const currentCase = state.context.taskData?.caseData
  const { goOffline } = useGoOfflineHook(currentCase || null)

  const agent = agentRef.current
  const currentContactId = state.context.taskMeta?.contactId
  const pseudoACW = state.matches({ busy: { after_call: "pseudo_acw" } })
  const { resetManualCallWhenOnline } = useManualCallAtom()
  const setModalMode = useSetAtom(modalModeAtom)

  // disable condition:
  // - agent is offline
  // - agent has a contact(connected or connecting) or pseudoACW(not connected)
  // - in manual calling panel(student details page)
  const disabledInManualCalling =
    !!matchStudentDetailsPage &&
    isInManualCall(state) &&
    (!!currentContactId || pseudoACW)

  const onChange = async (status: "offline" | "online") => {
    try {
      const isHandlingTask = state.matches("busy")
      const shouldDisplayGoOfflineModal = isHandlingTask && status === "offline"

      resetManualCallWhenOnline(status)

      if (shouldDisplayGoOfflineModal) {
        await showModal((close) => (
          <DialogGoOfflineWithContact onClose={close} onSkip={goOffline} />
        ))
      } else {
        await toggleAgentStatus(status)

        // Redirect to the call panel when agent goes online outside of the call panel
        const shouldRedirectToCallPanel =
          location.pathname !== routesConfig.AUTOMATIC_CALL_PANEL &&
          status === "online"

        if (shouldRedirectToCallPanel) {
          // This makes sure that we don't keep the "navigateWithContact" modal mode
          // in case it was set by the effect in redirectModal.ts
          // whe moving from /call-history/* to / route
          setModalMode(null)
          navigate(routesConfig.AUTOMATIC_CALL_PANEL, { replace: true })
        }
      }
    } catch (error) {
      log.error(error, { agent: agent?.toSnapshot() })
    }
    track(status)
  }

  return {
    isDisabled: disabledInManualCalling,
    onChange,
  }
}

function useTrackAgentStatusChange() {
  const { trackEvent } = useAnalytics()

  return (status: "offline" | "online") => {
    trackEvent(
      status === "online" ? userEventsMap.online : userEventsMap.offline,
    )
  }
}
