import { SubmitHandler } from "react-hook-form"

import { useAgentState } from "@/agent-state"
import * as callViewValue from "@/components/CallView/value"
import { isNull } from "@/helpers/typeguards"
import * as connectPanelValue from "@/pages/CustomConnectPanel/value"

export type ACWFormStatus =
  | "disabled" // initial state, we are not in "ACW state"
  | "normal_ACW" // voice contact ended
  | "pseudo_ACW" // after a failed connection

interface useACWFormHookInput {
  currentCase: connectPanelValue.Decoder.CaseAndActivityFeeds | null
}

const useACWFormHook = ({ currentCase }: useACWFormHookInput) => {
  const { agentRef, send, state } = useAgentState()
  const agentProfile = state.context.agentProfile
  const agent = agentRef.current

  const isACWState = state.matches({ busy: "after_call" })
  const isPseudoACW = state.matches({ busy: { after_call: "pseudo_acw" } })

  const onSave: SubmitHandler<callViewValue.Decoder.DecoderType> = async (
    data,
  ) => {
    const validData = await callViewValue.Decoder.schema.parseAsync(data)

    const firstName = agentProfile?.identity_info.first_name ?? ""
    const lastName = agentProfile?.identity_info.last_name ?? ""
    const email = agentProfile?.email ?? ""

    const isInboundCall =
      agent?.getContacts(connect.ContactType.VOICE)[0]?.isInbound() ?? false

    const formattedActivityEntry = connectPanelValue.ActivityFeedEntry.encode(
      validData,
      { firstName, lastName, email },
      currentCase?.case.campaign_id ?? "",
      isInboundCall ? "inbound" : "outbound",
    )

    send({ type: "SAVE_CALL_REPORT", callReport: formattedActivityEntry })
  }

  function getStatus(): ACWFormStatus {
    if (isPseudoACW) {
      return "pseudo_ACW"
    }

    if (isACWState) {
      return "normal_ACW"
    }

    return "disabled"
  }

  return {
    onSave,
    isPseudoACW,
    isLoadingAgent: isNull(agentProfile),
    status: getStatus(),
  }
}

export { useACWFormHook }
