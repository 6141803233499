import { useLocation } from "react-router"

import { useAgentState } from "@/agent-state"
import { routesConfig } from "@/config/routes"
import { contactStorageService } from "@/services/localStorageService"

type UseManualCallHookType = {
  resetInManualCall: () => void
  resetManualCallWhenOnline: (status: "offline" | "online") => void
  shouldClearAllStateInLocalStorage: boolean
}

const useManualCallAtom = (): UseManualCallHookType => {
  const { state } = useAgentState()

  const location = useLocation()

  const isACW = state.matches({ busy: "after_call" })

  /* Keep the processing time when logging out if we have a voice contact in progress (ACW state)
   in the automatic call panel */
  const withContactInProgress =
    isACW && location.pathname === routesConfig.AUTOMATIC_CALL_PANEL
  const shouldClearAllState = !withContactInProgress

  const resetInManualCall = () => {
    contactStorageService.removeManualCallCase()
  }

  /* Erase isManualCall value when switching to the automatic calling panel
     to prevent bugs in the `contact.onDestroy()` event handler */
  const resetManualCallWhenOnline = (status: "offline" | "online") => {
    if (status === "online") {
      resetInManualCall()
    }
  }

  return {
    resetManualCallWhenOnline,
    shouldClearAllStateInLocalStorage: shouldClearAllState,
    resetInManualCall,
  }
}

export { useManualCallAtom }
