import { z } from "zod"

import { useQueryAPI } from "@/hooks/query-api"

const schema = z.object({
  data: z.object({
    already_open: z.boolean(),
    task: z
      .object({
        task_id: z.string(),
        attributes: z.object({
          outbound_queue_arn: z.string(),
          customer_id: z.string(),
          phone_number: z.string(),
          campaign_id: z.string(),
        }),
        meta: z.object({
          assigned_to: z.string(),
        }),
      })
      .nullable(),
  }),
})

export function useFetchNextTask(campaignIds: string[]) {
  return useQueryAPI({
    apiEndPoint: "/task/next-available",
    params: { campaign_ids: campaignIds.join(",") },
    schema,
    options: {
      refetchInterval: 1000 * 30, // fetch every 30 seconds
      gcTime: 0,
      enabled: campaignIds.length > 0,
    },
  })
}
