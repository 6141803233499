/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react"

import { useAgentState } from "@/agent-state"
import { useFetchTaskData } from "@/agent-state/task-data"

import { LoadingTaskInboundView, LoadingTaskOutboundView } from "./loading-view"

export function TaskDataLoader() {
  const {
    send,
    state: {
      context: { taskMeta },
    },
  } = useAgentState()
  const { callDirection, campaignId, phoneNumber, studentId } = taskMeta || {}
  const { caseData, isError, isLoading, studentProfile } = useFetchTaskData({
    campaignId,
    phoneNumber,
    studentId,
  })

  useEffect(() => {
    if (caseData && studentProfile) {
      send({ type: "TASK_DATA_LOADED", task: { caseData, studentProfile } })
    }
    if (isError) {
      send({ type: "ENTER_RESET_MODE" })
    }
  }, [caseData?.case?.student_id, studentProfile?.student_id, isError])

  if (isLoading) {
    return callDirection === "inbound" ? (
      <LoadingTaskInboundView isInterrupted={taskMeta?.wasInterrupted} />
    ) : (
      <LoadingTaskOutboundView />
    )
  }

  return null // don't render anything as success and error states trigger transitions
}
