import { Pause, Play, RotateCcw, RotateCw } from "lucide-react"

import { Button } from "@/components/ui/button"

interface PlaybackControlsProps {
  disabled: boolean
  isPlaying: boolean
  onPlayPause: () => void
  onSkipBackward: () => void
  onSkipForward: () => void
}

const PlaybackControls = ({
  disabled,
  isPlaying,
  onPlayPause,
  onSkipBackward,
  onSkipForward,
}: PlaybackControlsProps) => {
  return (
    <div className="flex items-center gap-2">
      <Button
        variant="ghost"
        size="icon"
        onClick={onSkipBackward}
        disabled={disabled}
      >
        <RotateCcw className="size-4" />
      </Button>
      <Button
        variant="ghost"
        size="icon"
        onClick={onPlayPause}
        disabled={disabled}
      >
        {isPlaying ? <Pause className="size-4" /> : <Play className="size-4" />}
      </Button>
      <Button
        variant="ghost"
        size="icon"
        onClick={onSkipForward}
        disabled={disabled}
      >
        <RotateCw className="size-4" />
      </Button>
    </div>
  )
}

export { PlaybackControls }
