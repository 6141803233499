/* eslint-disable react-refresh/only-export-components */
import { FunctionComponent, PropsWithChildren } from "react"
import { useTranslation } from "react-i18next"
import { atom, useAtom, useSetAtom } from "jotai"

import { Button } from "@/components/ui/button"
import { ErrorCard } from "@/components/ui/card"
import { modalModeAtom } from "@/helpers/atoms"
import { cn } from "@/helpers/classNames"
import { useI18n } from "@/helpers/i18n/use-translation"
import { unique } from "@/helpers/other"
import {
  type CallErrorMap,
  callErrorMap,
  type VoiceCallError,
} from "@/models/call-errors"

const voiceCallErrorAtom = atom<Array<VoiceCallError>>([])

export const useErrorToast = () => {
  const [errors, setErrors] = useAtom(voiceCallErrorAtom)

  const pushCallError = (newMessage: keyof CallErrorMap) => {
    const error = callErrorMap[newMessage]

    setErrors((prevErrors) => {
      const newErrors = unique([...prevErrors, error])

      return newErrors
    })
  }

  const onClose = (message: VoiceCallError) => {
    setErrors((errors) => {
      return errors.filter((err) => err !== message)
    })
  }

  return { pushCallError, onClose, errors }
}

const CallErrorCard: FunctionComponent<
  PropsWithChildren & { callError: VoiceCallError }
> = ({ callError, children }) => {
  const { t } = useTranslation()
  const { onClose } = useErrorToast()

  return (
    <ErrorCard
      heading={t(`callPanel.callErrorCard.${callError}.title`)}
      onClose={() => onClose(callError)}
    >
      {children}
    </ErrorCard>
  )
}

const MicPermissionButton = () => {
  const { t } = useI18n()
  const setModalMode = useSetAtom(modalModeAtom)

  return (
    <div>
      <Button
        variant="secondary"
        size="sm"
        onClick={() => setModalMode({ mode: "microphoneNotShared" })}
      >
        {t("callPanel.callErrorCard.micPermissionError.button")}
      </Button>
    </div>
  )
}

const CallErrorDescription = ({ callError }: { callError: VoiceCallError }) => {
  const { tMap } = useI18n()

  return (
    <div>
      {tMap(
        `callPanel.callErrorCard.${callError}.description`,
        (descriptionText, i) =>
          Array.isArray(descriptionText) ? (
            <ul className="list-inside list-disc" key={i} data-unmask>
              {tMap(
                `callPanel.callErrorCard.${callError}.description.${i}`,
                (descSubtext, j) => (
                  <li key={j}>{descSubtext}</li>
                ),
              )}
            </ul>
          ) : (
            <p key={i}>{descriptionText}</p>
          ),
      )}
    </div>
  )
}

export const ShowErrorToast: FunctionComponent<{ className?: string }> = ({
  className,
}) => {
  const { errors } = useErrorToast()

  if (!errors.length) {
    return null
  }

  return (
    <div className={cn(className, "flex flex-col gap-y-2")}>
      {errors.map((callError, i) => (
        <CallErrorCard key={i} callError={callError}>
          <CallErrorDescription key={i} callError={callError} />
          {callError === "micPermissionError" && <MicPermissionButton />}
        </CallErrorCard>
      ))}
    </div>
  )
}

export function CallReportSubmitError({ className }: { className?: string }) {
  const { t } = useTranslation()

  return (
    <ErrorCard
      className={className}
      heading={t("callPanel.callErrorCard.submitError.title")}
    >
      {t("callPanel.callErrorCard.submitError.description")}
    </ErrorCard>
  )
}
