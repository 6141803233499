import { z } from "zod"

import LocalStorageService from "@/services/localStorageService/localStorageService"

const schema = z.object({
  isPseudoACW: z.boolean(),
  isManualCall: z.boolean(),
  hasAttemptedCall: z.boolean(),
})

export type PersistedAgentState = z.infer<typeof schema>

const defaultPersistedState: PersistedAgentState = {
  isPseudoACW: false,
  isManualCall: false,
  hasAttemptedCall: false,
}

class PersistedAgentStateService extends LocalStorageService {
  private readonly key = "persisted_state"
  private state: PersistedAgentState // Internal cache

  constructor() {
    super()
    // Initialize state from localStorage only once
    this.state = this.readFromStorage()
  }

  public read(): PersistedAgentState {
    return this.state
  }

  private readFromStorage(): PersistedAgentState {
    const rawData = this.getItem(this.key)
    const result = schema.safeParse(rawData)

    return result.success ? result.data : defaultPersistedState
  }

  private update(partialState: Partial<PersistedAgentState>) {
    this.state = { ...this.state, ...partialState }
    this.setItem(this.key, this.state)
  }

  public makeCall(isManualCall: boolean) {
    this.update({ hasAttemptedCall: true, isManualCall })
  }

  public enterPseudoACW() {
    this.update({ isPseudoACW: true })
  }

  public reset() {
    this.state = defaultPersistedState
    this.removeItem(this.key)
  }
}

export const persistenceService = new PersistedAgentStateService()
