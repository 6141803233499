import { useEffect } from "react"
import { useTimeout } from "react-use"

import { useAgentState } from "."
import { useFetchNextTask } from "./task-v2-api"

/**
 * Call the API to ask for the new Task V2, when the user is available
 * after a small delay to let AWS Connect emit Inbound Call events
 * */
export function NextV2TaskLoader() {
  const [hasDelayElapsed] = useTimeout(1_000)

  if (hasDelayElapsed()) {
    return <NextV2TaskLoaderInner />
  } else {
    return null
  }
}

function NextV2TaskLoaderInner() {
  const {
    send,
    state: {
      context: { queueNames },
    },
  } = useAgentState()
  const { data } = useFetchNextTask(queueNames || [])

  const taskId = data?.data?.task?.task_id
  const {
    campaign_id: campaignId,
    customer_id: studentId,
    phone_number: phoneNumber,
  } = data?.data?.task?.attributes || {}

  useEffect(() => {
    if (campaignId && studentId && phoneNumber && taskId) {
      send({
        type: "NEW_TASK",
        task: {
          campaignId,
          studentId,
          phoneNumber,
          callDirection: "outbound",
          origin: "v2",
          taskId,
          voiceId: null,
        },
      })
    }
  })

  return null
}
