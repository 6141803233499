import { PropsWithChildren, useRef } from "react"
import { createBrowserInspector } from "@statelyai/inspect"
import { useMachine } from "@xstate/react"

import { AgentStateContext } from "./context"
import { rootMachine } from "./machine-logic"
import { createSideEffectActions } from "./side-effect-actions"

const isDebugMode = import.meta.env.VITE_DEBUG === "1"

const inspector = isDebugMode ? createBrowserInspector() : undefined

export function AgentStateProvider({ children }: PropsWithChildren) {
  const agentRef = useRef<connect.Agent | null>(null)
  const actions = createSideEffectActions({ agentRef })
  const [state, send] = useMachine(rootMachine.provide({ actions }), {
    inspect: inspector?.inspect || undefined,
  })

  const providedValue = {
    state,
    send,
    agentRef,
  }

  if (isDebugMode) window.send = send

  return (
    <AgentStateContext.Provider value={providedValue}>
      {children}
    </AgentStateContext.Provider>
  )
}
