import { useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { CallStatusDef, Status, statusUsageMap } from "@repo/core/models"
import { orderBy } from "es-toolkit"

import { resolveLanguage } from "@/helpers/i18n"
import { isNonNull, isNull } from "@/helpers/typeguards"
import { useCallStatuses } from "@/hooks/call-statuses-context"
import { CallReportType, getUseCaseContext } from "@/hooks/useSendCallReport"

type Option = { label: string; value: string }
export type CallReportOption = { reasons: Option[]; status: Option }
type WithSortIndex<T> = T & { sortIndex: number }

export type CallReportOptionsProps = {
  callReportType: CallReportType
  inManualCall: boolean
  isInboundCall: boolean
  isPseudoACW: boolean
  latestRepeaterStatus: Status | null
}

const STATUS_GROUPS = {
  ROOT: "root",
  CALL_AGAIN: "callAgain",
  OUT: "out",
  SKIP: "skip",
} as const

export function useCallReportOptions({
  callReportType,
  inManualCall,
  isInboundCall,
  isPseudoACW,
  latestRepeaterStatus,
}: CallReportOptionsProps) {
  const { t } = useTranslation()
  const lang = resolveLanguage()
  const { getStatusesByUseCase } = useCallStatuses()
  const context = useMemo(
    () =>
      getUseCaseContext({
        callReportType,
        isPseudoACW,
        isInboundCall,
        inManualCall,
      }),
    [callReportType, isPseudoACW, isInboundCall, inManualCall],
  )

  const allStatuses = getStatusesByUseCase(context)

  // Adding a sort index to keep track of the original order
  const selectableStatuses = allStatuses.map(addSortIndex)

  const statusToOption = useCallback(
    (status: CallStatusDef): Option => ({
      label: status[lang],
      value: status.id,
    }),
    [lang],
  )

  /** Extract options with "reasons" used for `callAgain` and `out` groups */
  const getGroupOption = useCallback(
    (group: string): WithSortIndex<CallReportOption> | null => {
      const foundStatuses = selectableStatuses.filter(
        (status) => status.group === group,
      )

      return foundStatuses.length > 0
        ? {
            status: { value: group, label: t(`callResult.groups.${group}`) },
            reasons: foundStatuses.map(statusToOption),
            sortIndex: foundStatuses[0].sortIndex,
          }
        : null
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectableStatuses, statusToOption],
  )
  /** Extract "root" options (no "reasons") add latestRepeaterStatus as the first when in automaticePreCall */
  const getAllRootOptions = useCallback(
    (
      selectableStatuses: WithSortIndex<CallStatusDef>[],
      latestRepeaterStatus: string | null,
    ) => {
      const latestRepeaterStatusOption = isNull(latestRepeaterStatus)
        ? null
        : {
            status: {
              label: latestRepeaterStatus,
              value: latestRepeaterStatus,
            },
            reasons: [],
            sortIndex: -1,
          }

      // skip should also be considered root statuses?
      const isRootStatus = (status: CallStatusDef) =>
        status.group === STATUS_GROUPS.ROOT ||
        status.group === STATUS_GROUPS.SKIP

      const rootOptions: WithSortIndex<CallReportOption>[] = selectableStatuses
        .filter(
          (status) =>
            isRootStatus(status) && status.id !== latestRepeaterStatus,
        )
        .map((status) => ({
          status: statusToOption(status),
          reasons: [],
          sortIndex: status.id === latestRepeaterStatus ? -1 : status.sortIndex,
        }))

      return isNull(latestRepeaterStatus)
        ? rootOptions
        : [latestRepeaterStatusOption, ...rootOptions]
    },
    [statusToOption],
  )

  const rootOptions = getAllRootOptions(
    selectableStatuses,
    context === statusUsageMap.automaticPreCall ? latestRepeaterStatus : null,
  )
  const outOption = getGroupOption(STATUS_GROUPS.OUT)
  const callAgainOption = getGroupOption(STATUS_GROUPS.CALL_AGAIN)

  // Return the form options restoring the original order from the API
  return orderBy(
    [...rootOptions, callAgainOption, outOption].filter(isNonNull),
    [(item) => item.sortIndex],
    ["asc"],
  )
}

function addSortIndex<T>(item: T, index: number) {
  return { ...item, sortIndex: index }
}
