import { StrictMode } from "react"
import ReactDOM from "react-dom/client"

import Root from "./components/core/Root"

import "./helpers/i18n"
import "amazon-connect-streams"
import "amazon-connect-taskjs"

// Un-mounting and remounting components in strict mode don't play well with the iframe and X-State
// so React strict is not enabled by default
const isStrictMode = import.meta.env.VITE_STRICT_MODE === "1"
const $root = document.getElementById("root") as HTMLElement

ReactDOM.createRoot($root).render(
  isStrictMode ? (
    <StrictMode>
      <Root />
    </StrictMode>
  ) : (
    <Root />
  ),
)
