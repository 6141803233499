import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useAxiosClientContext } from "@/components/core/AxiosInstanceProvider/useAxiosClient"
import { PaginationParams } from "@/components/pagination/pagination-state"
import { useQueryAPI } from "@/hooks/query-api"
import { useLogger } from "@/hooks/useLogger"

import { CampaignListResponseSchema } from "../campaigns-types"

export function useMutationChangeCampaignStatus(campaign_id: string) {
  const axiosClient = useAxiosClientContext()
  const queryClient = useQueryClient()
  const log = useLogger()

  return useMutation({
    mutationFn: async (isEnabled: boolean) => {
      const action = isEnabled ? "start" : "stop"

      return await axiosClient.post(`/campaign-filters/${action}/v2`, {
        campaign_id,
      })
    },
    onSuccess: async () => {
      // Wait until the case list is updated to show the right status when closing the dialog
      return await queryClient.invalidateQueries({ queryKey: ["campaigns"] })
    },
    onError: (error) => {
      log.error(error)
    },
  })
}

export function useFetchCampaigns({
  enabled_campaigns_only,
  page,
  pageSize,
  search_query,
}: PaginationParams & {
  enabled_campaigns_only: boolean
  search_query: string
}) {
  return useQueryAPI({
    apiEndPoint: "/campaign-filters/get-campaigns",
    queryKey: ["campaigns"],
    params: {
      enabled_campaigns_only,
      page,
      page_size: pageSize,
      search_string: search_query,
    },
    schema: CampaignListResponseSchema,
  })
}
