import { PropsWithChildren } from "react"
import { Navigate } from "react-router"

import { useAgentState } from "@/agent-state"
import { NextV2TaskLoader } from "@/agent-state/task-v2-loader"
import Loader from "@/components/layout/Loader"
import ResetCallView from "@/components/ResetCallView"
import { useAgentProfile } from "@/hooks/agent-profile"

import { AgentMetricsPanel } from "./agent-metrics/agent-metrics-panel"
import { AvailableView } from "./available-view"
import { BusyView } from "./busy-view"
import { TaskDataLoader } from "./task-data-loader"

const CustomConnectPanel = () => {
  const { state } = useAgentState()

  // The agent being loaded, we don't know yet if the agent is online or offline
  if (state.matches("loading")) {
    return <Loader />
  }

  if (state.matches("offline")) {
    return (
      <ConnectPanelContainer>
        <Navigate to="/call-history" replace />
      </ConnectPanelContainer>
    )
  }

  if (state.matches("available")) {
    return (
      <ConnectPanelContainer showMetricsPanel>
        <AvailableView />
        <NextV2TaskLoader />
      </ConnectPanelContainer>
    )
  }

  if (state.matches({ busy: "loading_task" })) {
    return (
      <ConnectPanelContainer showMetricsPanel>
        <TaskDataLoader />
      </ConnectPanelContainer>
    )
  }

  if (state.matches({ busy: "reset" })) {
    return (
      <ConnectPanelContainer showMetricsPanel>
        <ResetCallView />
      </ConnectPanelContainer>
    )
  }

  // At this point we have a task and data related to the student and the case, display the call view!
  if (state.matches("busy")) {
    return (
      <ConnectPanelContainer showMetricsPanel>
        <BusyView />
      </ConnectPanelContainer>
    )
  }
}

function ConnectPanelContainer({
  children,
  showMetricsPanel,
}: PropsWithChildren<{ showMetricsPanel?: boolean }>) {
  const { agentProfile } = useAgentProfile()

  return (
    <div className="size-full">
      {showMetricsPanel && agentProfile && <AgentMetricsPanel />}
      {children}
    </div>
  )
}

export default CustomConnectPanel
