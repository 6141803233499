import { z } from "zod"

export const transcriptItemSchema = z.object({
  BeginOffsetMillis: z.number(),
  Content: z.string(),
  EndOffsetMillis: z.number(),
  Id: z.string(),
  LoudnessScore: z.array(z.number().nullable()),
  ParticipantId: z.enum(["AGENT", "CUSTOMER"]),
  Sentiment: z.enum(["NEUTRAL", "POSITIVE", "NEGATIVE"]),
})

export const transcriptResponseSchema = z.object({
  AccountId: z.string(),
  Categories: z.object({
    MatchedCategories: z.array(z.string()),
    MatchedDetails: z.record(z.unknown()),
  }),
  Channel: z.literal("VOICE"),
  ContentMetadata: z.object({
    Output: z.string(),
  }),
  ConversationCharacteristics: z.object({
    Interruptions: z.object({
      InterruptionsByInterrupter: z.record(z.unknown()),
      TotalCount: z.number(),
      TotalTimeMillis: z.number(),
    }),
    NonTalkTime: z.object({
      Instances: z.array(
        z.object({
          BeginOffsetMillis: z.number(),
          DurationMillis: z.number(),
          EndOffsetMillis: z.number(),
        }),
      ),
      TotalTimeMillis: z.number(),
    }),
    Sentiment: z.object({
      OverallSentiment: z.record(z.number()),
      SentimentByPeriod: z.object({
        QUARTER: z.record(
          z.array(
            z.object({
              BeginOffsetMillis: z.number(),
              EndOffsetMillis: z.number(),
              Score: z.number(),
            }),
          ),
        ),
      }),
    }),
    TalkSpeed: z.object({
      DetailsByParticipant: z.record(
        z.object({
          AverageWordsPerMinute: z.number(),
        }),
      ),
    }),
    TalkTime: z.object({
      DetailsByParticipant: z.record(
        z.object({
          TotalTimeMillis: z.number(),
        }),
      ),
      TotalTimeMillis: z.number(),
    }),
    TotalConversationDurationMillis: z.number(),
  }),
  CustomerMetadata: z.object({
    InputS3Uri: z.string(),
    ContactId: z.string(),
    InstanceId: z.string(),
  }),
  CustomModels: z.array(z.unknown()),
  JobStatus: z.literal("COMPLETED"),
  LanguageCode: z.string(),
  Participants: z.array(
    z.object({
      ParticipantId: z.enum(["AGENT", "CUSTOMER"]),
      ParticipantRole: z.enum(["AGENT", "CUSTOMER"]),
    }),
  ),
  Transcript: z.array(transcriptItemSchema),
  Version: z.string(),
})

export type TranscriptItem = z.infer<typeof transcriptItemSchema>
export type TranscriptResponse = z.infer<typeof transcriptResponseSchema>

export interface PlayerState {
  currentTime: number
  duration: number
  isPlaying: boolean
  speed: number
  status: "error" | "loading" | "ready"
}
