import { PropsWithChildren } from "react"

import { AgentProfile } from "@/agent-state/use-fetch-agent-profile"
import Header from "@/components/Header"
import Sidebar from "@/components/Sidebar"
import { cn } from "@/helpers/classNames"

type Props = PropsWithChildren<{
  agentProfile: AgentProfile | null
  isModalOpen?: boolean
}>

export function PageLayout({ agentProfile, children, isModalOpen }: Props) {
  return (
    <div
      className={cn([
        "flex size-full flex-col",
        isModalOpen && "overflow-hidden",
      ])}
    >
      <Header agentProfile={agentProfile} />
      <div className="flex grow flex-row">
        <Sidebar />
        <div className="grow overflow-x-hidden bg-neutral-200">{children}</div>
      </div>
    </div>
  )
}
