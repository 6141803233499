/* eslint-disable no-console */
import invariant from "tiny-invariant"

import { toggleConnectAgentStatus } from "./agent-status-hooks"
import { persistenceService } from "./persistence"
import { AgentState } from "./state-types"

type Props = { agentRef: React.MutableRefObject<connect.Agent | null> }

export function createSideEffectActions({ agentRef }: Props) {
  const actions = {
    // Enter the custom agent status of `PseudoACW` to make the agent non routable (no incoming calls)
    enterPseudoACW: ({ context }: { context: AgentState }) => {
      invariant(agentRef.current, "No agent provided")
      persistenceService.enterPseudoACW()
      if (context.taskMeta?.origin === "v2") {
        toggleConnectAgentStatus(agentRef.current, "pseudo_acw")
      }
    },
    // Back online after the V2 task is completed
    exitPseudoACW: ({ context }: { context: AgentState }) => {
      invariant(agentRef.current, "No agent provided")
      if (context.taskMeta?.origin === "v2") {
        console.log(">> [agent state] Going back online after V2 task")
        toggleConnectAgentStatus(agentRef.current, "online")
      }
    },
  }

  return actions
}
