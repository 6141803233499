import { AgentMachineSnapshot } from "./machine-logic"
import { AgentState } from "./state-types"

export function isHandlingV2Task(context: AgentState): boolean {
  return context.taskMeta?.origin === "v2"
}
export function isHandlingConnectTask(context: AgentState): boolean {
  return context.taskMeta?.origin === "connect"
}

export function isInManualCall(state: AgentMachineSnapshot): boolean {
  return state.matches("busy") && state.context.displayStatus === "offline"
}

/** Used to tack processing time for a given task (including inbound calls) */
export function getTaskOrContactId(
  context: AgentState,
  contact?: connect.Contact | null,
): string | undefined {
  return (
    context.taskMeta?.taskId ||
    context.taskMeta?.contactId ||
    contact?.getContactId()
  )
}
