import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router"
import { useAtom } from "jotai"

import { useGetConnectAgentStatus } from "@/agent-state/connect-listeners"
import { routesConfig } from "@/config/routes"
import { modalModeAtom, UndocumentedAgentStateType } from "@/helpers/atoms"
import { hasProperty, isValidModalMode } from "@/helpers/typeguards"
import { ModalMode } from "@/types/modes"

interface RedirectModalHookType {
  isLoading: boolean
  modalMode: ModalMode
  onClick: VoidFunction
}

const useRedirectModalHook = (): RedirectModalHookType => {
  const [modalMode, setModalMode] = useAtom(modalModeAtom)
  const agentStatus = useGetConnectAgentStatus()
  const [isLoading, setIsLoading] = useState(false)

  const navigate = useNavigate()
  const location = useLocation()

  const onClick = () => {
    setIsLoading(true)

    if (
      hasProperty("mode", isValidModalMode)(modalMode) &&
      modalMode.mode === "navigateWithContact"
    ) {
      setModalMode(null)
    }

    navigate(routesConfig.AUTOMATIC_CALL_PANEL, { replace: true })

    setIsLoading(false)
  }

  useEffect(() => {
    const isOffline = agentStatus === connect.AgentStateType.OFFLINE
    const isBusy =
      agentStatus === UndocumentedAgentStateType.SYSTEM ||
      agentStatus === UndocumentedAgentStateType.ERROR
    const isCallPanelRoute =
      location.pathname === routesConfig.AUTOMATIC_CALL_PANEL

    if (agentStatus === connect.AgentStateType.INIT) return // don't do anything while loading!
    if (!isOffline && !isBusy && !isCallPanelRoute) {
      setModalMode({
        mode: "navigateWithContact",
        state: { isCloseEnabled: false },
      })
    } else {
      setModalMode(null)
    }
  }, [agentStatus, location.pathname]) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    onClick,
    modalMode,
    isLoading,
  }
}

export { useRedirectModalHook }
