import { useTranslation } from "react-i18next"
import { useMount } from "react-use"

import { useAgentState } from "@/agent-state"
import CallView from "@/components/CallView"
import { buttonVariants } from "@/components/ui/button"
import { CallDurationProvider } from "@/hooks/callDuration"
import { useCallPanelLogger } from "@/hooks/useLogger"

export function BusyView() {
  const {
    state: {
      context: { taskData },
    },
  } = useAgentState()

  const { caseData, studentProfile } = taskData || {}

  if (!studentProfile || !caseData) return <InconsistentState />

  return (
    <CallDurationProvider>
      <CallView studentProfile={studentProfile} currentCase={caseData} />
    </CallDurationProvider>
  )
}

/** Not supposed to happen if the state machine is setup properly but we need to investigate if such a situation happens */
function InconsistentState() {
  const { t } = useTranslation()
  const log = useCallPanelLogger()

  useMount(() => {
    log.error("Inconsistent state")
  })

  return (
    <div className="flex flex-col gap-8 p-12 text-center text-lg">
      <p>⚠️ Missing data about case and/or student</p>
      <div>
        <a href="/" className={buttonVariants()}>
          {t("global.button.refreshPage")}
        </a>
      </div>
    </div>
  )
}
