import { formatStringValue as formatValue } from "@/helpers/valueFormat"
import { useFormValidation } from "@/hooks/formValidation"
import * as connectPanelValue from "@/pages/CustomConnectPanel/value"

interface ContactProfileHookInput {
  currentCase: connectPanelValue.Decoder.CaseAndActivityFeeds | null
  historyItem: connectPanelValue.ActivityFeedEntry.ActivityFeedEntryType | null
  studentProfile: connectPanelValue.Decoder.StudentProfile | null
}

interface ContactProfileHookType {
  contactName: string
  contactNameKana: string
  historyEntries: connectPanelValue.ActivityFeedEntry.ActivityFeedEntryType[]
}

const useContactProfileHook = ({
  currentCase,
  historyItem,
  studentProfile,
}: ContactProfileHookInput): ContactProfileHookType => {
  const { isKanji } = useFormValidation()

  const getNames = (): {
    contactName: string
    contactNameKana: string
  } => {
    const isJapaneseName = isKanji(
      studentProfile?.last_name || studentProfile?.first_name || "",
    )

    if (!isJapaneseName) {
      const contactName = `${studentProfile?.first_name ?? ""} ${
        studentProfile?.last_name ?? ""
      }`

      return {
        contactName: formatValue(contactName),
        contactNameKana: formatValue(null),
      }
    }

    const contactName = `${studentProfile?.last_name ?? ""}${
      studentProfile?.first_name ?? ""
    }`

    const contactNameKana = `${studentProfile?.last_name_kana ?? ""} ${
      studentProfile?.first_name_kana ?? ""
    }`

    return {
      contactName: formatValue(contactName),
      contactNameKana: formatValue(contactNameKana),
    }
  }

  const caseHistoryEntries = currentCase?.activity_feed ?? []
  const historyEntries = historyItem
    ? [historyItem, ...caseHistoryEntries]
    : caseHistoryEntries

  return {
    ...getNames(),
    historyEntries,
  }
}

function getLastQuestionAnswer<T>(questions: T[]) {
  // same question can be asked in multiple event forms with different answers
  return questions.at(-1)
}

function questionByTitle(
  questions: {
    answers: string[] | null
    question: string | null
  }[],
  title: string,
) {
  const similarQuestions = questions.filter(
    ({ question }) => question === title,
  )

  return getLastQuestionAnswer(similarQuestions)
}

export { getLastQuestionAnswer, questionByTitle, useContactProfileHook }
