import { useContext } from "react"

import { AgentStateContext } from "./context"

/** The hook to be called from anywhere to access the state machine and agent ref */
export function useAgentState() {
  const context = useContext(AgentStateContext)
  if (!context) {
    throw new Error("App must be used within a AgentStateProvider")
  }

  return context
}
