import { getTaskAttributes } from "@/helpers/contact"
import { getContactPhoneNumber } from "@/helpers/phoneNumber"
import { caseIdRegexp } from "@/helpers/zodSchemas"
import { useQueryAPI } from "@/hooks/query-api"
import * as value from "@/pages/CustomConnectPanel/value"

// TODO better typings
type TaskProps = {
  campaignId?: string
  phoneNumber?: string
  studentId?: string
}

export function useFetchTaskData({
  campaignId,
  phoneNumber,
  studentId,
}: TaskProps) {
  const caseQuery = useQueryAPI({
    apiEndPoint: `/case`,
    params: {
      campaign_id: campaignId,
      student_id: studentId,
      phone_number: phoneNumber,
    },
    schema: value.Decoder.caseInformationSchema,
  })

  const studentProfileQuery = useQueryAPI({
    apiEndPoint: `/students`,
    params: {
      student_id: studentId ?? undefined,
      phone_number: phoneNumber ?? undefined,
    },
    schema: value.Decoder.customerProfileSchema,
  })

  const studentProfile = studentProfileQuery.data?.data
  const caseData = caseQuery.data?.data
  const isLoading = caseQuery.isLoading || studentProfileQuery.isLoading
  const isError = caseQuery.isError || studentProfileQuery.isError

  return { studentProfile, caseData, isLoading, isError }
}

export function extractTaskProps(agent: connect.Agent) {
  const taskAttributes = getTaskAttributes(agent)

  if (!taskAttributes) {
    return null
  }

  const caseId = taskAttributes?.case_id?.value ?? null
  const phoneNumber = getPhoneNumber(agent)
  const campaignId = agent.getContacts()[0]?.getQueue()?.name ?? null
  const studentId = caseId ? parseCaseId(caseId).studentId : undefined

  return { caseId, phoneNumber, campaignId, studentId }
}

function parseCaseId(caseId: string) {
  const params = caseIdRegexp.exec(caseId)

  return {
    studentId: params?.groups?.studentId ?? null,
    campaignId: params?.groups?.campaign ?? null,
  }
}

/**
 * Return phone number from the provided task or inbound call.
 * If no agent, return null
 * If task, retrieve number from customer_phone_number attribute
 * If an inbound call has no number we can retrieve, return undefined.
 */
function getPhoneNumber(agent: connect.Agent) {
  const taskAttributes = getTaskAttributes(agent)

  if (!taskAttributes) {
    const phoneNumber = getContactPhoneNumber(agent)

    return phoneNumber ?? null
  }

  const phoneNumber = taskAttributes?.customer_phone_number?.value

  return phoneNumber ?? null
}
