import { useState } from "react"
import { useTranslation } from "react-i18next"
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router"
import { useMount } from "react-use"

import Loader from "@/components/layout/Loader"
import { usePaginationParams } from "@/components/pagination/pagination-state"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { useAgentProfile } from "@/hooks/agent-profile"

import {
  CallReport,
  CallReportSearchOptions,
  useFetchCallHistory,
} from "./call-history-schemas"
import { useCallHistorySearch } from "./call-history-search"
import { CallHistorySearchBar } from "./call-history-search-bar"
import { CallHistoryTable } from "./call-history-table"

/**
 * Main entry point for Call History
 * Works in both standalone mode (via direct route) and embedded mode (in CustomConnectPanel)
 */
export function CallReportsPage() {
  const now = useDefaultEndDate()
  if (!now) return null

  return (
    <Routes>
      <Route path="/" element={<CallReports currentDate={now} />}>
        <Route index element={<Navigate to="my-calls" replace />} />
        <Route path="my-calls" element={<MyCalls currentDate={now} />} />
        <Route path="all-calls" element={<AllCalls currentDate={now} />} />
      </Route>
    </Routes>
  )
}

// Helper function to determine the current tab value from path
function getTabValue(path: string) {
  if (path.includes("all-calls")) {
    return "all-calls"
  }

  return "my-calls"
}

/**
 * Layout component for Call History with tabs navigation
 */
function CallReports({ currentDate }: { currentDate: Date }) {
  const { t } = useTranslation()
  const { isLoading: isAgentProfileLoading, isManager } = useAgentProfile()
  const location = useLocation()
  const navigate = useNavigate()
  const { onChange, searchOptions } = useCallHistorySearch()
  const { agentIds } = searchOptions

  if (isAgentProfileLoading) {
    return <Loader />
  }

  // If user is not a manager, redirect to my-calls
  if (!isManager && location.pathname.includes("all-calls")) {
    return <Navigate to="../my-calls" replace />
  }

  const tabValue = getTabValue(location.pathname)

  const handleTabChange = (value: string) => {
    if (value === tabValue) return

    // Reset agent selection when switching to "my-calls" tab
    if (value === "my-calls" && agentIds.length > 0) {
      onChange("agentIds")([])
      // Reset the all_agents flag when switching to my-calls
      onChange("all_agents")(false)
    }

    // Set all_agents flag when switching to "all-calls" tab
    if (value === "all-calls") {
      // Set a special flag to query all agents
      onChange("all_agents")(true)
      // Clear any specific agent selections
      if (agentIds.length > 0) {
        onChange("agentIds")([])
      }
    }

    // Determine the correct navigation path
    const basePath = location.pathname.includes("/call-history")
      ? "/call-history"
      : ""

    navigate(`${basePath}/${value}`, { replace: true })
  }

  return (
    <main className="flex flex-col gap-y-2 px-8 pt-10">
      <h1 className="font-h1-bold" data-unmask>
        {t("callHistory.heading")}
      </h1>

      {isManager ? (
        <Tabs
          value={tabValue}
          onValueChange={handleTabChange}
          className="w-full"
        >
          <TabsList>
            <TabsTrigger value="my-calls">
              {t("callHistory.tabs.myCalls")}
            </TabsTrigger>
            <TabsTrigger value="all-calls">
              {t("callHistory.tabs.allCalls")}
            </TabsTrigger>
          </TabsList>
          <TabsContent value="my-calls">
            {tabValue === "my-calls" && <Outlet />}
          </TabsContent>
          <TabsContent value="all-calls">
            {tabValue === "all-calls" && <Outlet />}
          </TabsContent>
        </Tabs>
      ) : (
        <MyCalls currentDate={currentDate} />
      )}
    </main>
  )
}

/**
 * My Calls tab content
 */
function MyCalls({ currentDate }: { currentDate: Date }) {
  const defaultPageSize = 20
  const { page, pageSize } = usePaginationParams(defaultPageSize)
  const { onChange, searchOptions } = useCallHistorySearch()
  const { agentIds, campaignIds, date, search_query, statuses } = searchOptions
  const { data, isLoading } = useFetchCallHistory({
    date: { range: date.range, to: date.to || currentDate },
    pageSize,
    page,
    search_query,
    statuses,
    agentIds,
    campaignIds,
    all_agents: false, // Explicitly set to false for MyCalls
  })

  const callReports = data?.data.call_reports || []
  const total = data?.data.total || 0

  return (
    <CallHistoryContent
      onChange={onChange}
      searchOptions={searchOptions}
      callReports={callReports}
      isLoading={isLoading}
      total={total}
      page={page}
      pageSize={pageSize}
      showAgentsPicker={false}
    />
  )
}

/**
 * All Calls tab content (manager only)
 */
function AllCalls({ currentDate }: { currentDate: Date }) {
  const defaultPageSize = 20
  const { page, pageSize } = usePaginationParams(defaultPageSize)
  const { onChange, searchOptions } = useCallHistorySearch()
  const { agentIds, campaignIds, date, search_query, statuses } = searchOptions
  const { data, isLoading } = useFetchCallHistory({
    date: { range: date.range, to: date.to || currentDate },
    pageSize,
    page,
    search_query,
    statuses,
    agentIds,
    campaignIds,
    all_agents: true, // Always true for AllCalls tab
  })

  const callReports = data?.data.call_reports || []
  const total = data?.data.total || 0

  return (
    <CallHistoryContent
      onChange={onChange}
      searchOptions={searchOptions}
      callReports={callReports}
      isLoading={isLoading}
      total={total}
      page={page}
      pageSize={pageSize}
      showAgentsPicker={true}
    />
  )
}

/**
 * Call History content component (shared between tabs)
 */
function CallHistoryContent({
  callReports,
  isLoading,
  onChange,
  page,
  pageSize,
  searchOptions,
  showAgentsPicker = false,
  total,
}: {
  callReports: CallReport[]
  isLoading: boolean
  onChange: ReturnType<typeof useCallHistorySearch>["onChange"]
  page: number
  pageSize: number
  searchOptions: CallReportSearchOptions
  showAgentsPicker?: boolean
  total: number
}) {
  return (
    <>
      <CallHistorySearchBar
        onChange={onChange}
        searchOptions={searchOptions}
        showAgentsPicker={showAgentsPicker}
      />
      <CallHistoryTable
        callReports={callReports}
        isLoading={isLoading}
        total={total}
        page={page}
        pageSize={pageSize}
      />
    </>
  )
}

/**
 * Whenever the page is re-mounted (user going offline or navigating), reset the default "end date"
 * to ensure search results include latest data
 * TODO: the API should accept empty `to_date` to make queries with only a start date
 **/
function useDefaultEndDate() {
  const [date, setDate] = useState<Date | undefined>()

  useMount(() => {
    const now = new Date()
    setDate(now)
  })

  return date
}
