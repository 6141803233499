import { useTranslation } from "react-i18next"

import { MultiCombobox } from "@/components/ui/multi-combobox"
import { formatFullName } from "@/helpers/names"

import { LoadingFilter } from "../../Campaigns/pickers/generic-filter-picker"
import { useAgentList } from "../call-history-search"

type Props = {
  allowReset?: boolean
  onChange: (values: string[]) => void
  values: string[]
}

export function AgentsPicker({ allowReset, onChange, values }: Props) {
  const { t } = useTranslation()
  const { agentList, isLoading } = useAgentList()

  if (isLoading) return <LoadingFilter />

  const options =
    agentList?.map((agent) => ({
      value: agent.id,
      label: formatFullName(agent.first_name, agent.last_name),
    })) || []

  return (
    <MultiCombobox
      allowReset={allowReset}
      values={values}
      onChange={onChange}
      options={options}
      placeholder={t("callHistory.agentsFilterPlaceholder")}
      size="md"
    />
  )
}
