import React from "react"
import { useTranslation } from "react-i18next"

import { TranscriptItem } from "@/components/RecordingPlayer/types"
import { cn } from "@/lib/utils"

interface TranscriptBarProps {
  transcript: TranscriptItem[]
}

export const TranscriptBar: React.FC<TranscriptBarProps> = ({ transcript }) => {
  const { t } = useTranslation()
  const durationMillis =
    transcript.length > 0
      ? transcript[transcript.length - 1].EndOffsetMillis
      : 0
  const segments = transcript.map((segment, index) => {
    const endOfSegment =
      index === transcript.length - 1
        ? segment.EndOffsetMillis
        : transcript[index + 1].BeginOffsetMillis

    return {
      start: (segment.BeginOffsetMillis / durationMillis) * 100,
      width:
        ((endOfSegment - segment.BeginOffsetMillis) / durationMillis) * 100,
      isAgent: segment.ParticipantId === "AGENT",
    }
  })

  return (
    <div className="space-y-1">
      <div className="relative h-1.5 w-full overflow-hidden rounded-full bg-primary-400">
        {segments.map((segment, index) => (
          <div
            key={index}
            className={cn(
              "absolute h-full",
              segment.isAgent ? "bg-primary-100" : "bg-primary-400",
            )}
            style={{
              left: `${segment.start}%`,
              width: `${segment.width}%`,
            }}
          />
        ))}
      </div>
      <div className="flex items-center justify-center gap-4 text-xs text-muted-foreground">
        <div className="flex items-center gap-2">
          <div className="size-2 rounded-full bg-primary-100" />
          <span>{t("callHistory.role.agent")}</span>
        </div>
        <div className="flex items-center gap-2">
          <div className="size-2 rounded-full bg-primary-400" />
          <span>{t("callHistory.role.student")}</span>
        </div>
      </div>
    </div>
  )
}
