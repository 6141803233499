import { useEffect } from "react"
import { Outlet, useSearchParams } from "react-router"
import { useMount } from "react-use"
import { useAtomValue } from "jotai"

import { useAgentState } from "@/agent-state"
import { useFetchAgentProfile } from "@/agent-state/use-fetch-agent-profile"
import ConnectCCP from "@/components/core/ConnectCCP"
import Loader from "@/components/layout/Loader"
import { PageLayout } from "@/components/layout/page-layout"
import { ccpModeAtom, modalModeAtom } from "@/helpers/atoms"
import { getIframeContainer } from "@/helpers/ccp"
import { hasValue } from "@/helpers/typeguards"
import { useUnfinishedManualCallChecker } from "@/hooks/useUnfinishedManualCallChecker"
import ErrorPage from "@/pages/ErrorPage"

export function AppShell() {
  const { send } = useAgentState()
  const { data } = useFetchAgentProfile()

  const agentProfile = data?.data || null
  const ccpMode = useAtomValue(ccpModeAtom)
  const modalMode = useAtomValue(modalModeAtom)
  useUnfinishedManualCallChecker()
  useIframeDebugMode()

  useEffect(() => {
    if (agentProfile?.id) {
      send({ type: "AGENT_PROFILE_LOADED", agentProfile })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentProfile?.id])

  return (
    <PageLayout agentProfile={agentProfile} isModalOpen={hasValue(modalMode)}>
      {ccpMode.current === "LOADING" && <Loader isSlow={ccpMode.isSlow} />}
      {ccpMode.current === "INITIALIZED" && <Outlet />}
      {/* If the CCP panel fails to initialize properly (oauth error, exceeded the number of retries, etc.) */}
      {ccpMode.current === "ERROR" && <ErrorPage />}
      <ConnectCCP />
    </PageLayout>
  )
}

function useIframeDebugMode() {
  const [params] = useSearchParams()

  useMount(() => {
    const showIframe = params.has("debug")
    const container = getIframeContainer()
    if (showIframe) {
      container.style.display = "block"
    } else {
      container.style.display = "none"
    }
  })
}
