import { statusMap } from "@repo/core/models"
import { startOfMonth, startOfWeek, subDays } from "date-fns"
import { z } from "zod"

import { dateRangeSchema } from "@/components/date-range-nav"
import { useQueryAPI } from "@/hooks/query-api"

const callReportSchema = z.object({
  group_id: z.string(), // the call report unique ID
  connect_user_id: z.string(),
  last_name: z.string(),
  first_name: z.string(),
  phone_number: z.string(),
  email: z.string(),
  campaign_id: z.string(),
  student_id: z.string(),
  legacy_id: z.number(),
  status: z.nativeEnum(statusMap),
  reported_at: z.string(),
  processing_duration_seconds: z.number().nullable(),
  call_duration_seconds: z.number().nullable(),
  call_contact_id: z.string().nullable(),
  call_attempted: z.boolean(),
  call_type: z.string().nullable(),
  comment: z.string().nullable(),
  agent_info: z.object({
    email: z.string(),
    first_name: z.string(),
    last_name: z.string(),
  }),
})

export type CallReport = z.infer<typeof callReportSchema>

export const historyResponseSchema = z.object({
  data: z.object({
    call_reports: z.array(callReportSchema),
    total: z.number(),
  }),
})

export function useFetchCallHistory({
  agentIds,
  all_agents,
  campaignIds,
  date,
  page,
  pageSize,
  search_query,
  statuses,
}: {
  agentIds: string[]
  all_agents?: boolean
  campaignIds: string[]
  date: Required<CallReportSearchOptions["date"]>
  page: number
  pageSize: number
  search_query?: string
  statuses: string[]
}) {
  return useQueryAPI({
    apiEndPoint: "/call-reports/search",
    method: "POST",
    params: {
      page,
      page_size: pageSize,
      search_query,
      all_agents: all_agents && agentIds.length === 0 ? "true" : undefined,
      ...(date && {
        from_date: getStartDate(date),
        to_date: date.to,
      }),
    },
    data: {
      agentsConnectUserIds: agentIds,
      campaignIds,
      statuses,
    },
    schema: historyResponseSchema,
  })
}

function getStartDate(date: Required<CallReportSearchOptions["date"]>) {
  const { range, to } = date
  switch (range) {
    case "day": {
      const start = new Date(to)
      start.setHours(0, 0, 0, 0)

      return start
    }
    case "week":
      return startOfWeek(to)
    case "month":
      return startOfMonth(to)
    case "7days":
      return subDays(to, 7)
    case "30days":
      return subDays(to, 30)
  }
}

export const searchOptionsSchema = z.object({
  date: dateRangeSchema,
  statuses: z.array(z.string()).default([]),
  agentIds: z.array(z.string()).default([]),
  campaignIds: z.array(z.string()).default([]),
  search_query: z.string().optional(),
  all_agents: z.boolean().optional(),
})

export type CallReportSearchOptions = z.infer<typeof searchOptionsSchema>

export const agentListSchema = z.object({
  data: z.object({
    agents: z.array(
      z.object({
        id: z.string(),
        first_name: z.string(),
        last_name: z.string(),
        user_name: z.string(),
      }),
    ),
  }),
})

export type AgentList = z.infer<typeof agentListSchema>
