import { useAgentState } from "@/agent-state"
import { useCurrentContactData } from "@/agent-state/use-current-contact"

interface ContactDataHookType {
  callType: "inbound" | "outbound"
  currentTaskId?: string
  hasTask: boolean
  isInboundCall: boolean
  isTask: boolean
  isVoiceCallWithError: boolean
  isVoiceContact: boolean
  voiceContactId: string | null
}

/**
 * React hook that returns the various contact information required to POST a call report
 * for the current contact.
 *
 * @returns ContactDataHookType - object containing the relevant contact information
 */
const useContactDataHook = (): ContactDataHookType => {
  const { agentRef } = useAgentState()
  const agent = agentRef.current
  const { currentContactStatusType, currentContactType } =
    useCurrentContactData()
  const currentTaskId = agent
    ?.getContacts(connect.ContactType.TASK)[0]
    ?.getContactId()

  const voiceContact = agent?.getContacts(connect.ContactType.VOICE)[0]
  const callType = voiceContact?.isInbound() ? "inbound" : "outbound"
  const voiceContactId = voiceContact?.getContactId() ?? null
  const isVoiceContact = currentContactType === connect.ContactType.VOICE

  const isTask = currentContactType === connect.ContactType.TASK
  const isVoiceCallWithError =
    currentContactType === connect.ContactType.VOICE &&
    currentContactStatusType === connect.ContactStateType.ERROR

  const hasTask = Boolean(agent?.getContacts(connect.ContactType.TASK).length)

  return {
    voiceContactId,
    callType,
    isVoiceContact,
    isVoiceCallWithError,
    isTask,
    isInboundCall: voiceContact?.isInbound() ?? false,
    hasTask,
    currentTaskId,
  }
}

export { useContactDataHook }
