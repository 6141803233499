import { useEffect, useRef, useState } from "react"
import { useQuery } from "@tanstack/react-query"

import { useAxiosClientContext } from "@/components/core/AxiosInstanceProvider/useAxiosClient"

import {
  PlayerState,
  TranscriptResponse,
  transcriptResponseSchema,
} from "../types"

interface UseAudioPlayerProps {
  audioUrl: string
}

export function useAudioPlayer({ audioUrl }: UseAudioPlayerProps) {
  const audioRef = useRef<HTMLAudioElement | null>(null)
  const [playerState, setPlayerState] = useState<PlayerState>({
    isPlaying: false,
    currentTime: 0,
    duration: 0,
    speed: 1,
    status: "loading",
  })

  // Playback controls
  const togglePlay = () => {
    if (!audioRef.current) return

    if (playerState.isPlaying) {
      audioRef.current.pause()
    } else {
      audioRef.current.play()
    }

    setPlayerState((prev) => ({
      ...prev,
      isPlaying: !prev.isPlaying,
    }))
  }

  const seek = (time: number) => {
    if (!audioRef.current) return
    audioRef.current.currentTime = time
    setPlayerState((prev) => ({
      ...prev,
      currentTime: time,
    }))
  }

  const skip = (seconds: number) => {
    if (!audioRef.current) return
    const newTime = audioRef.current.currentTime + seconds
    const clampedTime = Math.min(
      Math.max(0, newTime),
      audioRef.current.duration,
    )
    seek(clampedTime)
  }

  // Initialize audio element
  useEffect(() => {
    try {
      audioRef.current = new Audio(audioUrl)
      const audio = audioRef.current

      // Set up audio event listeners
      audio.addEventListener("timeupdate", () => {
        setPlayerState((prev) => ({
          ...prev,
          currentTime: audio.currentTime,
        }))
      })

      audio.addEventListener("loadedmetadata", () => {
        setPlayerState((prev) => ({
          ...prev,
          duration: audio.duration,
          status: "ready",
        }))
      })

      audio.addEventListener("ended", () => {
        setPlayerState((prev) => ({
          ...prev,
          isPlaying: false,
          currentTime: 0,
        }))
        audio.currentTime = 0
      })

      audio.addEventListener("error", () => {
        setPlayerState((prev) => ({
          ...prev,
          status: "error",
        }))
      })

      // Set initial loading state
      setPlayerState((prev) => ({
        ...prev,
        status: "loading",
      }))

      return () => {
        audio.pause()
        audio.remove()
      }
    } catch (error) {
      setPlayerState((prev) => ({
        ...prev,
        status: "error",
      }))
    }
  }, [audioUrl])

  // Keyboard shortcuts
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      const { code, shiftKey } = event

      if (
        document.activeElement?.tagName === "INPUT" ||
        document.activeElement?.tagName === "TEXTAREA"
      ) {
        return
      }

      switch (code) {
        case "Space":
          event.preventDefault()
          togglePlay()
          break
        case "ArrowLeft":
          event.preventDefault()
          skip(shiftKey ? -10 : -5)
          break
        case "ArrowRight":
          event.preventDefault()
          skip(shiftKey ? 10 : 5)
          break
        default:
          break
      }
    }

    window.addEventListener("keydown", handleKeyPress)

    return () => window.removeEventListener("keydown", handleKeyPress)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setSpeed = (speed: number) => {
    if (!audioRef.current) return
    audioRef.current.playbackRate = speed
    setPlayerState((prev) => ({
      ...prev,
      speed,
    }))
  }

  return {
    playerState,
    controls: {
      togglePlay,
      seek,
      setSpeed,
      skip,
    },
  }
}

export function useFetchTranscript({
  transcriptUrl,
}: {
  transcriptUrl?: string
}) {
  const axiosClient = useAxiosClientContext()

  async function queryFn(): Promise<TranscriptResponse> {
    if (!transcriptUrl) {
      throw new Error("Transcript URL is required")
    }

    const response = await axiosClient.get<TranscriptResponse>(transcriptUrl, {
      headers: {
        Authorization: undefined,
      },
    })

    return transcriptResponseSchema.parse(response.data)
  }

  const { data, isLoading } = useQuery<TranscriptResponse>({
    queryKey: ["transcript", transcriptUrl],
    queryFn,
    enabled: !!transcriptUrl,
  })

  return {
    transcriptData: data,
    isLoading,
  }
}
