import { useTranslation } from "react-i18next"

import Icon from "@/components/Icon"
import ProgressBar from "@/components/ProgressBar"

export function RequestWorkView() {
  const { t } = useTranslation()

  return (
    <div
      className="flex size-full flex-col items-center justify-center gap-y-4 px-34.5"
      data-sentry-unmask
      data-testid="request-work-loading"
    >
      <div className="flex flex-col items-center">
        <Icon name="folder-empty" size="xl" />
        <h4 className="font-h4-bold text-neutral-700">
          {t("callPanel.requestWork.title")}
        </h4>
        <p className="font-body-medium-regular">
          {t("callPanel.requestWork.subtitle.0")}
        </p>
        <p className="font-body-medium-regular">
          {t("callPanel.requestWork.subtitle.1")}
        </p>
      </div>
      <div className="flex w-[360px] items-center justify-center">
        <ProgressBar />
      </div>
    </div>
  )
}
