/**
 * Return the hidden div in `index.html` used to mount the CCP iframe.
 * Kept outside of React world to avoid problems with Strict Mode and Vite HMR
 **/
export function getIframeContainer() {
  const element = document.getElementById("connect-iframe-container")
  if (!element) {
    throw new Error("Iframe container not found in the page")
  }

  return element
}
